import { ContentNamingType, convertTicketId } from '@eeedo/utils';
import moment from 'moment';

import type { FuseResult } from 'fuse.js';

import { DATE_FORMAT } from 'src/Utilities/dates';

import type { TicketSearch } from './types';
import type { TicketListTicket } from 'src/types/Ticket';

export const mapTicketToSearch =
  () =>
  (ticket: TicketListTicket): TicketSearch => {
    const dueDate = ticket.dueDate ? moment.unix(ticket.dueDate).format(DATE_FORMAT) : '';
    const created = ticket.created ? moment.unix(ticket.created).format(DATE_FORMAT) : '';

    return {
      ...ticket,
      created: created,
      dueDate: dueDate
    };
  };

const DATE_FORMATS = ['D.M.YYYY', 'YYYY-M-D', 'D-M-YYYY', 'D/M/YYYY', 'M/D/YYYY'];

export const matchDate = (searchText: string): moment.Moment | null => {
  const regex = /([\d./-]{8,10})/;
  const result = regex.exec(searchText)?.[1];
  const date = moment(result ?? searchText, DATE_FORMATS, true);
  return date.isValid() ? date : null;
};

export const getMatchedTicketId = (ticketId: number, tickets: TicketSearch[]) => {
  const parsedTicketId = convertTicketId<string>(ticketId, ContentNamingType.String);
  const ticket = tickets.find((ticket) => ticket.id === parsedTicketId);

  return ticket ? ticket.id : null;
};

export const removeParamsFromSearchText = (
  searchText: string,
  params: (string | number | undefined | null)[]
): string => {
  return params.reduce((acc: string, param) => acc.replace(new RegExp(`${param}`, 'gi'), ''), searchText).trim();
};

export const getExtendedSearchText = (searchText = '', params: (string | number | undefined | null)[]): string => {
  const paramsFiltered = params.filter(Boolean) as (string | number)[];
  const searchTextWithoutParams = removeParamsFromSearchText(searchText, params);

  return [searchTextWithoutParams, ...paramsFiltered].filter(Boolean).join(' | ');
};

export const mapSearchItems = <T>({ item }: FuseResult<T>): T => item;
