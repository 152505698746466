import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Menu } from 'semantic-ui-react';

import type { FC } from 'react';
import type { DropdownItemProps } from 'semantic-ui-react';

import { CREATE_INFO_PAGE, CREATE_TICKET } from 'src/actions';
import { fetchTemplates } from 'src/actions/templateActions';
import Dropdown from 'src/Components/generic/Dropdown/Dropdown';
import { selectAllowedContentTemplates } from 'src/selectors/rootStateSelectors';
import { useAppSelector } from 'src/store';

import type { TabBarMethod } from 'src/types/TabBar';
import type { Template, TemplateContentJson } from 'src/types/Template';

interface AddNewProps {
  method: TabBarMethod;
  createContent?: (template?: Template<TemplateContentJson>) => void;
}

const AddNew: FC<AddNewProps> = ({ method, createContent }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false);
  const templates = useAppSelector(selectAllowedContentTemplates);
  const isCreateTicketInProgress = useAppSelector((state) =>
    state.ajaxCallsInProgress.some((ajaxCall) => [CREATE_TICKET, CREATE_INFO_PAGE].includes(ajaxCall.name))
  );

  const onItemClick = useCallback(
    (template?: Template<TemplateContentJson>) => () => {
      if (createContent) {
        createContent(template);
        setIsOpen(false);
      }
    },
    [createContent, setIsOpen]
  );

  const onToggleMenu = useCallback(
    (value: boolean) => () => {
      setIsOpen(value);
    },
    [setIsOpen]
  );

  const isSuitableMethod = method === 'tickets' || method === 'infopages';

  useEffect(() => {
    if (isSuitableMethod) {
      dispatch(fetchTemplates());
    }
  }, [isSuitableMethod]);

  if (isSuitableMethod && templates.length) {
    const options: DropdownItemProps[] = templates
      .filter((template) => !template.template.contentType || template.template.contentType === method)
      .map((template, index) => ({
        key: `add-new-template-${index}`,
        text: template.name,
        value: template.id,
        disabled: isCreateTicketInProgress,
        onClick: onItemClick(template)
      }));

    options.unshift({
      key: 'add-new-template-default',
      text: t('Default content'),
      value: undefined,
      selected: false,
      disabled: isCreateTicketInProgress,
      onClick: onItemClick()
    });

    return (
      <Menu.Menu position="left">
        <Dropdown
          item
          text={t('NEW')}
          style={{ zIndex: 1000 }}
          options={options}
          searchMenuEnabled
          onMouseEnter={onToggleMenu(true)}
          onMouseLeave={onToggleMenu(false)}
          onOpen={onToggleMenu(true)}
          open={isOpen}
          icon={
            <span>
              <FontAwesomeIcon id="addNewTicketTabBtn" className="TabBar-New-Icon" icon={faPlus} />
            </span>
          }
        />
      </Menu.Menu>
    );
  }

  return (
    <Menu.Item className="TabBar-Tab" onClick={onItemClick()}>
      <span>{t('NEW')}</span>
      <span>
        <FontAwesomeIcon id="addNewTicketTabBtn" className="TabBar-New-Icon" icon={faPlus} />
      </span>
    </Menu.Item>
  );
};

export default AddNew;
