import type { IFuseOptions } from 'fuse.js';

import type { TicketSearch } from './types';
import type { CommandPrompt } from './useCommands';

export const searchTicketOptions: IFuseOptions<TicketSearch> = {
  keys: [
    { name: 'id', weight: 3 },
    { name: 'title', weight: 2 },
    'created',
    'dueDate',
    'lastContactAddress',
    'originalContact',
    'status'
  ],
  threshold: 0.5,
  minMatchCharLength: 1,
  useExtendedSearch: true,
  ignoreLocation: true
};

export const getSearchCommandsOptions: (isCommand: boolean) => IFuseOptions<CommandPrompt> = (isCommand) => ({
  keys: ['searchText'],
  threshold: isCommand ? 0.5 : 0.1,
  minMatchCharLength: isCommand ? 1 : 3
});
