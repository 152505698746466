import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Icon, Input, Label, Popup, TextArea } from 'semantic-ui-react';

import type { ISalesforceSettingsData } from '@eeedo/types';
import type { FC } from 'react';

import DateTimePicker from '../../DateTimePicker';
import { replaceObjectParameters } from '../../Utilities';
import SalesforceMemoSelect from './SalesforceMemoSelect';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';

import type { ReplySalesforceState } from '../ReplySalesforce';
import type { IGeneralSalesforceData, SalesforceFormPayload, SalesforceObjectType } from 'src/types/Salesforce';

interface IProps {
  validationErrors: { [key: string]: any };
  salesforceSettings: ISalesforceSettingsData;
  payload: SalesforceFormPayload;
  accounts: IGeneralSalesforceData[];
  users: IGeneralSalesforceData[];

  handleSetState: (fields: Partial<ReplySalesforceState['payload']>) => void;
  handleSelectChange: (property: string, value: string) => void;
  setSearchableSelectFields: (type: SalesforceObjectType, stringToSearch: string) => void;
}

const SalesforceOpportunityForm: FC<IProps> = ({
  salesforceSettings,
  validationErrors,
  payload,
  handleSetState,
  handleSelectChange,
  accounts,
  users,
  setSearchableSelectFields
}) => {
  const { t } = useTranslation();

  return (
    <>
      {!salesforceSettings.hiddenFields.includes('opportunity.Name') && (
        <Form.Field
          required
          error={
            validationErrors.Name && {
              content: validationErrors.Name,
              pointing: 'above'
            }
          }
          control={Input}
          value={payload.Name}
          label={t('GENERAL_NAME')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleSetState({
              Name: event.target.value
            })
          }
        />
      )}

      {!salesforceSettings.hiddenFields.includes('opportunity.Description') && (
        <Form.Field
          error={
            validationErrors.Description && {
              content: validationErrors.Description,
              pointing: 'above'
            }
          }
          control={TextArea}
          value={payload.Description}
          label={t('GENERAL_DESCRIPTION')}
          onChange={(event: React.SyntheticEvent, data: { [key: string]: any }) =>
            handleSetState({
              Description: data.value
            })
          }
        />
      )}

      {!salesforceSettings.hiddenFields.includes('opportunity.StageName') && (
        <SalesforceMemoSelect
          value={payload.StageName}
          fieldName="StageName"
          items={salesforceSettings.opportunityStages.map((statusName: string) => ({
            text: statusName,
            value: statusName
          }))}
          errorMessage={validationErrors.StageName}
          handleState={handleSelectChange}
          label={t('salesforce_reply.stage_name')}
        />
      )}

      {!salesforceSettings.hiddenFields.includes('opportunity.Amount') && (
        <Form.Field
          error={
            validationErrors.Amount && {
              content: validationErrors.Amount,
              pointing: 'above'
            }
          }
          control={Input}
          value={payload.Amount}
          label={t('GENERAL_AMOUNT')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleSetState({
              Amount: parseInt(event.target.value)
            })
          }
        />
      )}

      {!salesforceSettings.hiddenFields.includes('opportunity.CloseDate') && (
        <Form.Field>
          <label>{t('GENERAL_CLOSE_DATE')}</label>

          <Popup
            position="top center"
            wide={true}
            hoverable={true}
            flowing={true}
            trigger={
              <DateTimePicker
                onChange={({ date }) =>
                  handleSetState({
                    CloseDate: date || undefined
                  })
                }
                trigger={({ setIsOpen }) => (
                  <Label
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    <Icon name="time" />
                    <span>{getPrettyDate(payload.CloseDate, { format: DATE_TIME_FORMAT })}</span>
                  </Label>
                )}
                startDate={payload.CloseDate}
              />
            }
            content={t('TOOLTIP_DUEDATE')}
          />
        </Form.Field>
      )}

      {!salesforceSettings.hiddenFields.includes('opportunity.Account') && (
        <SalesforceMemoSelect
          value={payload.AccountId}
          fieldName="AccountId"
          items={accounts
            .sort((a, b) => (a.Name > b.Name ? 1 : -1))
            .map((singleItem) => ({
              text: salesforceSettings.accountFieldView
                ? replaceObjectParameters(salesforceSettings.accountFieldView, singleItem)
                : singleItem.Name,
              value: singleItem.Id
            }))}
          errorMessage={validationErrors.AccountId}
          handleState={handleSelectChange}
          onSearchChange={(event: React.SyntheticEvent, data: { [key: string]: any }) =>
            setSearchableSelectFields('Account', data.searchQuery)
          }
          label={t('GENERAL_ACCOUNT')}
          search
        />
      )}

      {!salesforceSettings.hiddenFields.includes('opportunity.Owner') && (
        <SalesforceMemoSelect
          value={payload.OwnerId || salesforceSettings.opportunityDefaultOwnerId}
          fieldName="OwnerId"
          items={users
            .sort((a, b) => (a.Name > b.Name ? 1 : -1))
            .map((singleItem) => ({
              text: salesforceSettings.userFieldView
                ? replaceObjectParameters(salesforceSettings.userFieldView, singleItem)
                : singleItem.Name,
              value: singleItem.Id
            }))}
          errorMessage={validationErrors.OwnerId}
          handleState={handleSelectChange}
          onSearchChange={(event: React.SyntheticEvent, data: { [key: string]: any }) =>
            setSearchableSelectFields('User', data.searchQuery)
          }
          label={t('GENERAL_OWNER')}
          search
        />
      )}
    </>
  );
};

export default React.memo(SalesforceOpportunityForm);
