import type { TicketType, Widget, WidgetName } from '@eeedo/types';

import type { Ticket } from 'src/types/Ticket';

const defaultWidgetOrder: WidgetName[] = [
  'SuggestedArticles',
  'CaseChannel',
  'Priority',
  'HandleStatus',
  'Delegations',
  'Tags',
  'AttachEntityById',
  'CustomerInfo',
  'FieldSets',
  'ExternalLinks',
  'Surveys',
  'Attachments'
];

const defaultWidgets: Widget[] = defaultWidgetOrder.map((name) => ({ name, defaultClosed: false }));

export const getWidgetOrder = (
  task: Ticket,
  ticketTypes: TicketType[],
  selectedWidgetName?: string,
  onlyActive?: boolean
) => {
  const ticketTypesData = (ticketTypes || []).find(({ name }) => name === task?.taskType);
  if (!ticketTypesData) {
    return defaultWidgets;
  }

  const ticketTypesWidgetOrder = ticketTypesData.widgetOrder?.length
    ? ticketTypesData.widgetOrder.map((widget) =>
        typeof widget === 'string' ? ({ name: widget, defaultClosed: false } as Widget) : widget
      )
    : [];
  const widgetOrder = [
    ...ticketTypesWidgetOrder,
    ...defaultWidgets.filter((dWidget) => !ticketTypesWidgetOrder.some((tWidget) => tWidget.name === dWidget.name))
  ].filter((widget) => (widget.show === undefined ? true : widget.show));

  const activeWidgetIndex = widgetOrder.findIndex(
    (widget) => selectedWidgetName === (widget.displayName ? widget.displayName : widget.name)
  );

  if (activeWidgetIndex !== -1) {
    widgetOrder[activeWidgetIndex] = { ...widgetOrder[activeWidgetIndex], isActive: true };
  }

  return !onlyActive ? widgetOrder : widgetOrder.filter(({ isActive }) => isActive);
};
