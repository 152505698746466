import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuHeader } from 'semantic-ui-react';

import type { CSSProperties, FC, PropsWithChildren } from 'react';

import SearchAndCommandPaletteItem from './SearchAndCommandPaletteItem';
import Button from 'src/Components/generic/Button/Button';
import Label from 'src/Components/generic/Label/Label';

import type { TicketSearch } from './types';
import type { CommandPrompt } from './useCommands';

interface Props {
  commands?: CommandPrompt[];
  fluid?: boolean;
  onClickShowMore?: () => void;
  results?: number;
  tickets?: TicketSearch[];
  title?: React.ReactNode;
  labels?: { id: string | number; content: string; style?: CSSProperties; onClick: () => void }[];
}

const SearchAndCommandPaletteBox: FC<PropsWithChildren<Props>> = ({
  children,
  commands,
  fluid,
  onClickShowMore,
  results,
  tickets,
  title,
  labels
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('menu visible command_palette_box', { fluid })}>
      {title && (
        <MenuHeader>
          {title}
          {!!results && (
            <small style={{ float: 'right' }}>
              {results} {t('GENERAL_SEARCH_RESULTS')}
            </small>
          )}
        </MenuHeader>
      )}

      {tickets?.map((ticket) => (
        <SearchAndCommandPaletteItem ticket={ticket} key={ticket.id} />
      ))}

      {commands?.map((command) => (
        <SearchAndCommandPaletteItem promptOption={command} key={command.value} />
      ))}

      {!!labels?.length && (
        <div className="command_palette_box_labels">
          {labels?.map((label) => (
            <Label key={label.id} onClick={label.onClick} content={label.content} style={label.style} />
          ))}
        </div>
      )}

      {children}

      {onClickShowMore && (
        <MenuHeader>
          <Button content={t('GENERAL_SHOW_MORE')} onClick={onClickShowMore} size="xs" type="normal" />
        </MenuHeader>
      )}
    </div>
  );
};

export default SearchAndCommandPaletteBox;
