import { ChannelType } from '@eeedo/types';

export const getChannelTypeKey = (value: number): string | undefined => {
  const keys = Object.keys(ChannelType).filter((k) => typeof ChannelType[k as any] === 'number') as string[];
  for (const key of keys) {
    if (ChannelType[key as keyof typeof ChannelType] === value) {
      return key;
    }
  }
  return undefined;
};

export const exampleChannels = [
  {
    id: 1,
    channel: 'Sähköposti',
    icon: 'mail',
    color: 'green',
    active: 1
  },
  {
    id: 2,
    channel: 'Puhelin',
    icon: 'phone',
    color: 'yellow',
    active: 1
  },
  {
    id: 3,
    channel: 'Verkkolomake',
    icon: 'wpforms',
    color: 'gray',
    active: 1
  }
];
