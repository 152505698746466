import { isEmpty } from 'lodash-es';
import { connect } from 'react-redux';

import { clearInfopagelistFilter, setInfopagelistFilter } from '../actions/infoPagesActionsRTK';
import FilterWrapper from '../Components/ticketList/FilterWrapper';
import { clearTicketlistFilter, setTicketlistFilter, setTicketlistSorting } from 'src/actions/ticketListTabActionsRTK';
import { getURLFilterParams } from 'src/Utilities/helper';

import type { IFilterWrapperDispatchToProps, IFilterWrapperStateToProps } from '../Components/ticketList/FilterWrapper';
import type { IFilterItem } from 'src/types/Filter';
import type { State } from 'src/types/initialState';
import type { MenuTab } from 'src/types/MenuTab';
import type { ContentTypesFields } from 'src/types/Ticket';

interface OwnProps {
  contentType: ContentTypesFields;
}

const mapStateToProps = (state: State, ownProps: OwnProps): IFilterWrapperStateToProps => {
  const tabs: MenuTab[] =
    ownProps.contentType === 'infopages' ? [...state.infoPageListTabs.values()] : Object.values(state.ticketListTabs);
  const tab = tabs.find((t) => t.activeTab);

  return {
    categories: state.categories,
    ticketTypesMetadata: state.ticketTypesMetadata,
    personalData: state.userData,
    ticketTypes: state.ticketTypes,
    channels: state.channels,
    tab,
    tags: state.tags,
    usersList: state.usersList.usersList,
    priorities: state.ticketPriorities,
    isUsingUrlParams: true,
    filters: !isEmpty(window.location.search) ? getURLFilterParams(window.location.search) : tab?.filters,
    isFullWidthContainer: false
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => ({
  setFilter: (item: IFilterItem) => {
    dispatch((ownProps.contentType === 'infopages' ? setInfopagelistFilter : setTicketlistFilter)(item));
  },
  clearAllFilters: (id: string) => {
    dispatch((ownProps.contentType === 'infopages' ? clearInfopagelistFilter : clearTicketlistFilter)(id));
  },
  setSorting:
    ownProps.contentType === 'tickets'
      ? (...args: Parameters<typeof setTicketlistSorting>) => {
          dispatch(setTicketlistSorting(...args));
        }
      : undefined
});

export default connect<IFilterWrapperStateToProps, IFilterWrapperDispatchToProps, OwnProps, State>(
  mapStateToProps,
  mapDispatchToProps
)(FilterWrapper);
