import { faRefresh } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ButtonGroup } from 'semantic-ui-react';

import type { FC } from 'react';

import css from './Tab.module.css';
import { fetchTickets } from 'src/actions/ticketsActions';
import Button from 'src/Components/generic/Button/Button';
import { setTicketListActiveTab, setToolsActiveTab } from 'src/reducers/ticketListActiveTabsReducer';
import { useAppSelector } from 'src/store';
import { StaticTabs } from 'src/types/TicketList';

const MainTab: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [inactive, setInactive] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);

  const activeTabId = useAppSelector((state) => state.ticketListActiveTabs.main) ?? StaticTabs.MAIN_VIEW;
  const activeButton = React.useMemo(() => activeTabId === StaticTabs.MAIN_VIEW, [activeTabId]);

  useEffect(() => () => clearTimeout(timeoutId), []);

  const onTabClick = useCallback(() => {
    dispatch(setToolsActiveTab());
    dispatch(setTicketListActiveTab(StaticTabs.MAIN_VIEW));
  }, []);
  const onIconClick = useCallback(() => {
    if (inactive) {
      return;
    }

    setInactive(true);

    const id = setTimeout(() => {
      setInactive(false);
    }, 5000);
    setTimeoutId(id);

    dispatch(fetchTickets());
  }, []);

  const className = classNames(css.active, { [css.inactive]: inactive });

  return (
    <ButtonGroup>
      <Button active={activeButton} size="xs" borderless onClick={onTabClick}>
        {t('MAIN_VIEW')}
      </Button>
      <Button
        size="md"
        active={activeButton}
        disabled={inactive}
        className={className}
        borderless
        onClick={onIconClick}
        icon={<FontAwesomeIcon icon={faRefresh} />}
      />
    </ButtonGroup>
  );
};

export default React.memo(MainTab);
