import { ascend, pipe, prop, sortWith, toLower } from 'ramda';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import type { TicketTypeMetadata } from '@eeedo/types';
import type { ComponentProps, CSSProperties, FC } from 'react';
import type { ConnectedProps } from 'react-redux';
import type { DropdownProps } from 'semantic-ui-react';

import Dropdown from './Dropdown/Dropdown';
import FeatureFlags from 'src/api/FeatureFlags';
import { getEntityTypes } from 'src/Utilities/ticketTypes';

import type { State } from 'src/types/initialState';

import './TicketTypeDropdown.css';

export interface TicketTypeDropdownProps extends ConnectedProps<typeof connector> {
  value: string | null;
  style?: CSSProperties;
  entityType?: string;
  disabled?: ComponentProps<typeof Dropdown>['disabled'];
  loading?: ComponentProps<typeof Dropdown>['loading'];
  clearable?: ComponentProps<typeof Dropdown>['clearable'];
  error?: ComponentProps<typeof Dropdown>['error'];
  restrictUserAccess?: boolean;

  handleTypeChange(value: string): void;
}

const mapTicketTypeMetadata = ({ name, id, canAdd, allowed }: TicketTypeMetadata) => ({
  id,
  text: name,
  value: name,
  key: `${name}-${id}`,
  // TODO MONGODB Remove canAdd !== undefined when we stop using mongo
  disabled: canAdd !== undefined && !canAdd,
  ...(allowed
    ? {}
    : {
        icon: 'angle right',
        className: 'ticketTypeDropdownForbiddenTicketTypeName'
      })
});

type MetadataOption = ReturnType<typeof mapTicketTypeMetadata>;

const toLowerProp = (propName: keyof MetadataOption) =>
  pipe<MetadataOption, string>(prop(propName) as any, toLower as any);
const byLowercasedText = ascend(toLowerProp('text'));
const byLowercasedValue = ascend(toLowerProp('value'));
const sortOptions = sortWith<MetadataOption>([byLowercasedText, byLowercasedValue]);

const TicketTypeDropdown: FC<TicketTypeDropdownProps> = ({
  value,
  style,
  error = false,
  loading = false,
  disabled = false,
  clearable = false,
  entityType,
  ticketTypes,
  ticketTypesMetadata,
  restrictUserAccess = false,
  handleTypeChange
}) => {
  const { t } = useTranslation();

  const entityTypes = useMemo(() => getEntityTypes(ticketTypes), [ticketTypes]);
  const ticketTypeOptions = useMemo(() => {
    const featureForbiddenAllowed = FeatureFlags.isFlagOn('ALLOW_CHANGING_TO_FORBIDDEN_TICKET_TYPES');
    const ticketTypeOptions = restrictUserAccess
      ? ticketTypes.map((ticketType) => mapTicketTypeMetadata({ ...ticketType, allowed: true }))
      : ticketTypesMetadata
          ?.filter(
            (type) =>
              (type.canAdd !== false || // TODO MONGODB Remove type.canAdd === undefined when we stop using mongo
                type.name === value) && // show current value even if cannot add
              (featureForbiddenAllowed || type.allowed) // if feature enabled add all otherwise only allowed
          )
          .map(mapTicketTypeMetadata) ?? [];

    return sortOptions(
      entityType
        ? ticketTypeOptions.filter((option) =>
            entityTypes.find((entityRoute) => entityRoute.ticketType === option.id)?.entityType.includes(entityType)
          )
        : ticketTypeOptions
    );
  }, [ticketTypesMetadata, value]);

  const onChange = useCallback<NonNullable<DropdownProps['onChange']>>((_event, data) => {
    handleTypeChange(data.value?.toString() ?? '');
  }, []);

  return (
    <Dropdown
      style={style}
      selectOnBlur={false}
      value={value ?? undefined}
      id="ticketTypeDropdown"
      error={error}
      loading={loading}
      disabled={disabled}
      clearable={clearable}
      placeholder={t('PLACEHOLDER_TICKET_TYPE')}
      onChange={onChange}
      options={ticketTypeOptions}
      selection
      search
    />
  );
};

const connector = connect((state: State) => ({
  ticketTypesMetadata: state.ticketTypesMetadata,
  ticketTypes: state.ticketTypes
}));

export default connector(TicketTypeDropdown);
