import { faEraser, faFloppyDisk, faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { Dropdown, Icon } from 'semantic-ui-react';

import type { CSSProperties, FC } from 'react';

import Button from '../../generic/Button/Button';
import { useCommandsContext } from '../../Layout/Desktop/SearchAndCommandPalette/CommandsContext';
import styles from './ReplyControlButtons.module.css';
import FeatureFlags from 'src/api/FeatureFlags';
import { TicketStatusAfterCommentSubmit } from 'src/types/Salesforce';

interface RPBProps {
  small?: boolean;
  disabled?: boolean;
  disabledCloseAsDone?: boolean;
  internal?: boolean;
  loading?: boolean;
  containerStyles?: CSSProperties;

  onClear: () => void;
  onSaveDraft?: () => void;
  onSubmit: () => void;
  onSubmitAndTicketStatusChange?: (status: TicketStatusAfterCommentSubmit) => void;
}

const ReplyControlButtons: FC<RPBProps> = ({
  disabled,
  disabledCloseAsDone,
  internal,
  loading,
  containerStyles,
  onClear,
  onSaveDraft,
  onSubmit,
  onSubmitAndTicketStatusChange
}) => {
  const { t } = useTranslation();
  const onClickSend = () => {
    if (!disabled) {
      onSubmit();
    }
  };

  const isEnabledSendAndCloseAsDone =
    FeatureFlags.isFlagOn('SHOW_SEND_AND_CLOSE_AS_DONE') &&
    !disabled &&
    !disabledCloseAsDone &&
    onSubmitAndTicketStatusChange;

  const onClickSendAndStatusChange = useCallback(
    (status: TicketStatusAfterCommentSubmit) => () => {
      if (isEnabledSendAndCloseAsDone) {
        onSubmitAndTicketStatusChange(status);
      }
    },
    [onSubmitAndTicketStatusChange]
  );

  useHotkeys('ctrl+enter', onClickSend, undefined, [onClickSend]);
  useHotkeys('alt+s', onClickSendAndStatusChange(TicketStatusAfterCommentSubmit.done), undefined, [
    onClickSendAndStatusChange
  ]);

  const { setSendAndCloseAsDoneFunc } = useCommandsContext();
  useEffect(() => {
    setSendAndCloseAsDoneFunc(() =>
      isEnabledSendAndCloseAsDone ? onClickSendAndStatusChange(TicketStatusAfterCommentSubmit.done) : null
    );
    return () => setSendAndCloseAsDoneFunc(null);
  }, [onClickSendAndStatusChange, isEnabledSendAndCloseAsDone]);

  const submitButtonText = internal ? t('GENERAL_SAVE') : t('GENERAL_SEND');

  return (
    <div style={{ marginBottom: '15px', display: 'flex', gap: '5px', ...containerStyles }}>
      <Button
        type="error"
        iconLeft={<FontAwesomeIcon icon={faEraser} />}
        onClick={onClear}
        content={t('GENERAL_CLEAR')}
      />

      <Button
        // id="commentReplySaveBtn"
        type="primary"
        disabled={disabled || loading}
        iconLeft={<FontAwesomeIcon icon={internal ? faFloppyDisk : faPaperPlane} />}
        onClick={onClickSend}
        content={submitButtonText}
      />

      {typeof onSubmitAndTicketStatusChange === 'function' && FeatureFlags.isFlagOn('SHOW_SEND_AND_CLOSE_AS_DONE') && (
        <Button
          onClick={() => onSubmitAndTicketStatusChange(TicketStatusAfterCommentSubmit.done)}
          disabled={disabled || disabledCloseAsDone}
          content={<span>{t('GENERAL_SEND_AND_CLOSE')}</span>}
          type="primary"
          iconRight={
            <Dropdown
              selectOnNavigation={false}
              selectOnBlur={false}
              trigger={<Icon name="chevron down" />}
              icon={false}
              loading={loading}
              disabled={disabled || disabledCloseAsDone}
            >
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => onSubmitAndTicketStatusChange(TicketStatusAfterCommentSubmit.todo)}>
                  <Icon name="exclamation" />
                  <span>{t('GENERAL_SEND_AND_CLOSE_AS_TODO')}</span>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onSubmitAndTicketStatusChange(TicketStatusAfterCommentSubmit.doing)}>
                  <Icon name="clock" />
                  <span>{t('GENERAL_SEND_AND_CLOSE_AS_DOING')}</span>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onSubmitAndTicketStatusChange(TicketStatusAfterCommentSubmit.done)}>
                  <Icon name="check" />
                  <span>{t('GENERAL_SEND_AND_CLOSE')}</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          }
        />
      )}

      <div className={styles.flexGrow} />

      {typeof onSaveDraft === 'function' && (
        <Button
          // id="descriptionFieldEditSaveDraftBtn"
          iconLeft={<FontAwesomeIcon icon={faFloppyDisk} />}
          onClick={onSaveDraft}
          content={t('GENERAL_SAVE_DRAFT')}
        />
      )}
    </div>
  );
};

export default React.memo(ReplyControlButtons);
