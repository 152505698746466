import type { TFunction } from 'i18next';

export const translateTitle = (t: TFunction, title: string) => {
  const translatableTitles = ['MAIN_VIEW', 'NEW', 'TAB_NAME_SEARCH'];
  if (translatableTitles.includes(title)) {
    return t(title);
  }
  if (title.substring(0, 17) === 'TAB_NAME_CUSTOMER') {
    return `${t(title.substring(0, 17))}${title.substring(17)}`;
  }

  if (title.startsWith('TSK') || title.startsWith('INF')) {
    return title.substring(3);
  }
  console.log('TAB_NAME_CUSTOMER', title);
  return title;
};
