import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonGroup } from 'semantic-ui-react';

import type { FC } from 'react';

import css from './Tab.module.css';
import Button from 'src/Components/generic/Button/Button';
import { translateTitle } from 'src/Utilities/translateTitle';

import type { InfoPageListTab } from 'src/types/InfoPageList';
import type { TicketListTab as ITicketListTab } from 'src/types/TicketList';

interface TicketListTabProps {
  tab: ITicketListTab | InfoPageListTab;
  activeTabId: string;
  onSelectTab: (tabId: string) => void;
  onCloseTab: (tabId: string) => void;
}

const TicketListTab: FC<TicketListTabProps> = ({ tab, activeTabId, onSelectTab, onCloseTab }) => {
  const { t } = useTranslation();

  const activeTab = React.useMemo(() => tab.id === activeTabId, [tab.id, activeTabId]);
  const className = classNames(css.active);
  return (
    <ButtonGroup>
      <Button borderless active={activeTab} size="xs" type="normal" onClick={() => onSelectTab(tab.id)}>
        {translateTitle(t, tab.title)}
      </Button>
      <Button
        size="md"
        borderless
        active={activeTab}
        type="normal"
        onClick={() => onCloseTab(tab.id)}
        className={className}
        icon={<FontAwesomeIcon icon={faClose} />}
      ></Button>
    </ButtonGroup>
  );
};

export default React.memo(TicketListTab);
