import classNames from 'classnames';
import React from 'react';
import { Icon, Input as SInput } from 'semantic-ui-react';

import type { FC } from 'react';
import type { InputOnChangeData, InputProps as SemanticInputProps } from 'semantic-ui-react';

import PasswordShowHideButton from './PasswordShowHideButton';

import './Input.css';

interface InputProps extends SemanticInputProps {
  value?: string;
  label?: string;
  hint?: string;
  errorMessage?: string;
  placeholder?: string;
  disabled?: boolean;
  action?: React.ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;
}

const Input: FC<InputProps> = ({
  value,
  icon,
  label,
  hint,
  placeholder,
  iconPosition,
  disabled,
  errorMessage,
  action,
  type,
  onChange,
  ...rest
}) => {
  const [inputType, setInputType] = React.useState(type || 'text');
  const wrapperClasses = classNames({ 'input-wrapper': true, error: !!errorMessage, password: type === 'password' });

  const renderAction = React.useMemo(() => {
    if (type === 'password') return <PasswordShowHideButton type={type} inputType={inputType} onClick={setInputType} />;
    if (!action) return undefined;
    return action;
  }, [action, type, inputType]);

  return (
    <div className={wrapperClasses}>
      {label && <label>{label}</label>}
      <SInput
        iconPosition={iconPosition ? 'left' : undefined}
        icon={icon}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        type={inputType}
        action={renderAction}
        {...rest}
      />
      {(hint || errorMessage) && (
        <div className="hint">
          <Icon name="info circle" /> {hint ?? errorMessage}
        </div>
      )}
    </div>
  );
};

export default React.memo(Input);
