import type { EnvSettingsData } from '@eeedo/types';

export default class EnvSettings {
  private static settings: EnvSettingsData = {
    OC_URL: '',
    ELISARING_URL: '',
    MITEL_URL: '',
    ENREACH_VOICE_URL: '',
    GUIDE_URL: '',
    REPORTING_PAGE_URL: '',
    KNOWLEDGE_BASE_URL: '',
    MAX_SEARCH_RESULTS: 1000,
    FILTER_SYSTEM_EMAIL: 'true',
    TICKETLIST_INTERVAL: 30000,
    INFO_PAGE_LIST_INTERVAL: 500,
    PRIORITY_MAIL_THRESHOLD_NOTIFICATION: null,
    SOUND_NOTIFICATION_CHANNELS: { data: { channels: [] } },
    OPEN_EXTERNAL_LINK: { data: { fieldName: '' } },
    CALLS_TO_SAME_TICKET: { data: {} },
    AUTOMATIC_CLOSING_TICKETS: { data: { delay: 15, channel: [], status: ['doing'] } },
    GOOGLE_API_KEY: '',
    AI_ASSISTANT: { data: { name: '', iconName: '' } },
    SALESFORCE: {
      value: '',
      data: {
        hiddenFields: [],
        taskStatuses: [],
        leadStatuses: [],
        opportunityStages: [],
        contactFieldsToFetch: [],
        accountFieldsToFetch: [],
        userFieldsToFetch: [],
        contactFieldView: '',
        accountFieldView: '',
        userFieldView: '',
        taskDefaultOwnerId: '',
        leadDefaultOwnerId: '',
        opportunityDefaultOwnerId: '',
        leadDefaultStatus: '',
        taskDefaultStatus: '',
        opportunityDefaultStage: '',
        fieldsMapping: {
          case: {},
          task: {},
          lead: {},
          opportunity: {}
        },
        closeDateInSeconds: true,
        dueDateInSeconds: true
      }
    },
    CHATSANITIZATION: {
      value: 'chatComment',
      data: {
        allowedTags: [],
        selfClosing: [],
        allowedSchemes: [],
        allowedAttributes: {},
        disallowedTagsMode: 'discard'
      }
    },
    SANITIZATION: {
      value: 'userComment',
      data: {
        allowedTags: [
          'address',
          'article',
          'aside',
          'footer',
          'header',
          'h1',
          'h2',
          'h3',
          'h4',
          'h5',
          'h6',
          'hgroup',
          'main',
          'nav',
          'section',
          'blockquote',
          'dd',
          'div',
          'dl',
          'dt',
          'figcaption',
          'figure',
          'hr',
          'li',
          'main',
          'ol',
          'p',
          'pre',
          'ul',
          'abbr',
          'b',
          'bdi',
          'bdo',
          'br',
          'cite',
          'code',
          'data',
          'dfn',
          'em',
          'i',
          'kbd',
          'mark',
          'q',
          'rb',
          'rp',
          'rt',
          'rtc',
          'ruby',
          's',
          'samp',
          'small',
          'span',
          'strong',
          'sub',
          'sup',
          'time',
          'u',
          'var',
          'wbr',
          'caption',
          'col',
          'colgroup',
          'table',
          'tbody',
          'td',
          'tfoot',
          'th',
          'thead',
          'tr'
        ],
        selfClosing: ['br', 'hr'],
        allowedSchemes: [],
        allowedAttributes: {},
        disallowedTagsMode: 'escape',
        allowProtocolRelative: true,
        allowedSchemesAppliedToAttributes: []
      }
    },
    LOCALIZATION: {
      countryIso2: '',
      countryIso3: '',
      countryCode: ''
    },
    FACEBOOK_PROXY_URL: '',
    DEFAULT_SEARCH: {
      value: null,
      data: []
    },
    ESSI_ARCHIVAL_OPTIONS: { data: { documentTypes: [], queues: [], allowedFileTypes: [] } },
    FEEDBACK_URL: ''
  };

  static setSettings(incomingSettings: EnvSettingsData) {
    this.settings = {
      ...this.settings,
      ...incomingSettings
    };
  }

  static getSettings() {
    return this.settings;
  }
}
