import { ChannelType } from '@eeedo/types';
import React from 'react';
import { useSelector } from 'react-redux';

import OpenInWindowButton from '../OpenInWindowButton';
import TopBarCopyDropdown from '../TopBarCopyDropdown';
import FeatureFlags from 'src/api/FeatureFlags';
import AdvancedActions from 'src/Components/Case/TopBar/AdvancedActions';

import type { State } from 'src/types/initialState';

import './TopBarButtonsGroup.css';

const TopBarButtonsGroup = () => {
  const task = useSelector(
    (state: State) => state.detailedTickets.find((ticket) => ticket.id === state.activeTicketTab)!
  );

  const showOpenInWindowButton =
    FeatureFlags.isFlagOn('ENABLE_FLOATING_WINDOW') &&
    (task.channel === ChannelType.Giosg || task.channel === ChannelType.Chat || task.type === 'infopage');

  return (
    <div className="topBarButtonsGroup">
      {showOpenInWindowButton && <OpenInWindowButton ticket={task} />}

      <TopBarCopyDropdown contentType={task.type} title={task.title} caseId={task.id} taskCreated={task.created} />

      <AdvancedActions />
    </div>
  );
};

export default TopBarButtonsGroup;
