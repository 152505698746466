import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useLayoutEffect } from 'react';

import './Textarea.css';

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
  hint?: string;
  isDisabled?: boolean;
  autoHeight?: boolean;
}

const Textarea: React.FC<TextareaProps> = ({
  label,
  error,
  hint,
  isDisabled = false,
  className = '',
  autoHeight = false,
  onChange,
  onBlur,
  onKeyDown,
  ...props
}) => {
  const refTextArea = React.useRef<HTMLTextAreaElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!event.target.value.length) {
      adjustHeight();
    }

    onChange?.(event);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    autoHeight && adjustHeight();
    onKeyDown?.(event);
  };

  const adjustHeight = () => {
    if (refTextArea.current) {
      refTextArea.current.style.height = 'auto';
      refTextArea.current.style.height = `${refTextArea.current.scrollHeight}px`;
    }
  };

  useLayoutEffect(() => {
    if (autoHeight) {
      adjustHeight();
    }
  }, [autoHeight]);

  const textareaClasses = classNames(
    {
      error,
      textarea: true,
      disabled: isDisabled
    },
    className
  );

  return (
    <div className="textarea-container">
      {label && <label className="textarea-label">{label}</label>}
      <textarea
        ref={refTextArea}
        className={textareaClasses}
        disabled={isDisabled}
        onChange={handleChange}
        onBlur={onBlur}
        onKeyDown={handleKeyDown}
        {...props}
      />
      {(error || hint) && (
        <div className={`textarea-info ${error ? 'error' : ''}`}>
          <FontAwesomeIcon icon={error ? 'exclamation-triangle' : 'info'} />
          <span>{error || hint}</span>
        </div>
      )}
    </div>
  );
};

export default React.memo(Textarea);
