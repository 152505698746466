import classNames from 'classnames';
import React from 'react';

import type { FC, ReactNode } from 'react';

import styles from './CommentHeader.module.css';

interface Props {
  avatar: ReactNode;
  compact?: boolean;
  date?: ReactNode;
  description?: ReactNode;
  extra?: ReactNode;
  name: ReactNode;
  appearence?: 'ticketHeader';
}

const CommentHeader: FC<Props> = ({ avatar, name, date, extra, description, compact, appearence }) => {
  return (
    <div
      className={classNames(
        styles.commentHeaderContainer,
        compact && styles.compact,
        appearence === 'ticketHeader' && styles.ticketHeaderContainer
      )}
    >
      <div className={styles.avatar}>{avatar}</div>
      <div className={styles.header}>
        <div className={styles.name}>
          <strong>{name}</strong>
          {extra && <div className={styles.extra}>{extra}</div>}
          <div className={styles.date}>{date}</div>
        </div>
        {description && <div className={styles.description}>{description}</div>}
      </div>
    </div>
  );
};

export default React.memo(CommentHeader);
