import { faChevronRight, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Form, Icon, Message } from 'semantic-ui-react';

import type { AxiosError } from 'axios';
import type { FC } from 'react';

import Button from './Components/generic/Button/Button';
import Input from './Components/generic/Input/Input';
import { useAppThunkDispatch } from './store';
import { forgotPassword } from 'src/actions/authActions';

import type { State } from 'src/types/initialState';

const ForgotPassword: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppThunkDispatch();
  const history = useHistory();
  const isUserAuthenticated = useSelector((state: State) => state.auth.loggedIn);
  const [email, setEmail] = useState('');
  const [confirmationState, setConfirmationState] = useState<{
    isOpen: boolean;
    type: 'info' | 'error';
    title: string;
    message: string;
  }>({
    isOpen: false,
    type: 'info',
    title: '',
    message: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    setConfirmationState({ ...confirmationState, isOpen: false });
    setIsLoading(true);

    dispatch(forgotPassword(email))
      .unwrap()
      .then(() => {
        setConfirmationState({
          isOpen: true,
          type: 'info',
          title: t('GENERAL_REQUEST_SUBMITTED'),
          message: t('LOGIN_FORGOTPASSWORD_REQUEST_SUBMITTED')
        });
      })
      .catch((error: AxiosError) => {
        setConfirmationState({
          isOpen: true,
          type: 'error',
          title: t('GENERAL_REQUEST_FAILED'),
          message:
            error.response?.data?.['msg'] === 'NOT_ALLOWED_FEATURE' ? t('NOT_ALLOWED_FEATURE') : t('ERROR_TRY_AGAIN')
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="loginWrapper">
      {isUserAuthenticated ? (
        <Redirect to="/" />
      ) : (
        <>
          <div className="logo loginLogo" />
          <div className="loginForm">
            <Form>
              <div className="loginHeader">
                <h1>{t('LOGIN_FORGOTPASSWORD_TITLE')}</h1>
                <h2 style={{ textAlign: 'center' }}>{t('LOGIN_FORGOTPASSWORD_INSTRUCTIONS')}</h2>
              </div>

              <div className="loginFormManualLogin">
                <Input
                  label="Email"
                  name="email"
                  placeholder={t('PLACEHOLDER_EMAIL')}
                  onChange={handleChange}
                  value={email}
                />

                <Button
                  type="primary"
                  size="lg"
                  style={{ marginTop: '14px' }}
                  iconRight={<FontAwesomeIcon icon={faChevronRight} />}
                  content={t('LOGIN_FORGOTPASSWORD_SEND')}
                  onClick={handleSubmit}
                  disabled={isLoading || !email}
                  center
                />

                <Button
                  type="normal"
                  size="lg"
                  content={t('LOGIN_FORGOTPASSWORD_RETURN')}
                  onClick={() => history.push('/login')}
                  center
                />
              </div>

              {confirmationState.isOpen && (
                <Message icon size="small" style={{ posititon: 'relative' }}>
                  <Icon name="info circle" color={confirmationState.type === 'info' ? 'blue' : 'red'} />

                  <Message.Content style={{ padding: '0 15px 0 0' }}>
                    <Message.Header>{confirmationState.title}</Message.Header>
                    <div dangerouslySetInnerHTML={{ __html: confirmationState.message }} />
                  </Message.Content>

                  <FontAwesomeIcon
                    onClick={() => setConfirmationState({ ...confirmationState, isOpen: false })}
                    icon={faXmark}
                    style={{ position: 'absolute', top: '16px', right: '16px', cursor: 'pointer' }}
                    size="lg"
                  />
                </Message>
              )}
            </Form>
          </div>
        </>
      )}
    </div>
  );
};

export default ForgotPassword;
