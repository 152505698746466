import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import type { FC } from 'react';

import FilterPopupContent from './FilterPopupContent';
import UnifiedMenuItemWrapper from './UnifiedMenuItemWrapper';
import Popup from 'src/Components/generic/Popup/Popup';

export interface MenuItem {
  icon: IconDefinition;
  text: string;
  href?: string;
  filterCounts?: { [key: string]: number };
  action?: (...args: any[]) => void;
  isActive?: boolean;
}

interface Props {
  item: MenuItem;
}

const UnifiedMenuItem: FC<Props> = ({ item }) => {
  const { pathname } = useLocation();
  const isActive = item.isActive || (item.href && !!matchPath(pathname, { path: item.href, exact: true }));

  return (
    <UnifiedMenuItemWrapper href={item.href} action={item.action}>
      <div className={classNames('layout-desktop-unified-menu-item', isActive && 'active')}>
        <FontAwesomeIcon icon={item.icon} />

        <Popup
          trigger={
            <div className="layout-desktop-unified-menu-item-text">
              {item.text}

              {item.filterCounts && (
                <div className="layout-desktop-unified-menu-item-count">{item.filterCounts.todo ?? 0}</div>
              )}
            </div>
          }
          disabled={!item?.filterCounts}
          content={item?.filterCounts ? <FilterPopupContent filter={item} /> : null}
          position="right center"
        />
      </div>
    </UnifiedMenuItemWrapper>
  );
};

export default UnifiedMenuItem;
