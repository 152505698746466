import React, { createContext, useContext, useState } from 'react';

import type { FunctionComponent } from 'react';

interface WidgetViewContextType {
  selectedWidget: string;
  isSingleWidgetView: boolean;
  isRightSidebarOpen: boolean;

  setSelectedWidget: (widget: string) => void;
  toggleWidgetView: () => void;
  toggleRightSidebar: () => void;
}

const WidgetViewContext = createContext<WidgetViewContextType | undefined>(undefined);

export const useWidgetView = () => {
  const context = useContext(WidgetViewContext);
  if (context === undefined) {
    throw new Error('useWidgetView must be used within a WidgetViewProvider');
  }
  return context;
};

export const WidgetViewProvider: FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  // TODO: default for demo
  const [selectedWidget, setSelectedWidget] = useState<string>('CustomerInfo');
  const [isSingleWidgetView, setSingleWidgetView] = useState<boolean>(true);
  const [isRightSidebarOpen, setRightSidebarOpen] = useState<boolean>(true);

  const toggleWidgetView = () => {
    setSingleWidgetView(!isSingleWidgetView);
  };

  const toggleRightSidebar = () => {
    setRightSidebarOpen(!isRightSidebarOpen);
  };

  return (
    <WidgetViewContext.Provider
      value={{
        toggleWidgetView,
        setSelectedWidget,
        toggleRightSidebar,
        selectedWidget,
        isSingleWidgetView,
        isRightSidebarOpen
      }}
    >
      {children}
    </WidgetViewContext.Provider>
  );
};
