import { filter } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import type { Widget } from '@eeedo/types';
import type { ConnectedProps } from 'react-redux';

import AccordionHeader from '../AccordionHeader';
import Delegates from '../Delegates';
import { addDelegateGroupToContent, addDelegateToContent, removeDelegateFromContent } from 'src/actions/ticketsActions';

import type { State } from 'src/types/initialState';
import type { Ticket } from 'src/types/Ticket';

interface DelegationsProps extends ConnectedProps<typeof connector> {
  id: string;
  task: Ticket;
  widgetOpen: boolean;
  widget: Widget;
}

const Delegations = ({
  id,
  task,
  widget,
  widgetOpen,
  ticketTypes,
  autosuggestions,
  user,
  usersList,
  userData,
  addDelegateGroupToContent,
  removeDelegateFromContent,
  addDelegateToContent
}: DelegationsProps) => {
  const { t } = useTranslation();
  const taskTicketType = ticketTypes.find((x) => x.name === task.taskType);
  const delegates = filter(task.delegatedTo, (delegate) => delegate !== 'USR1');

  const handleDelegateChange = (usr: string, method: string) => {
    if (!task) {
      return;
    }

    if (typeof task.id !== 'undefined') {
      switch (method) {
        case 'DELETE':
          removeDelegateFromContent(task.id, usr);
          break;
        case 'POST':
        default:
          addDelegateToContent(task.id, usr);
          break;
      }
    }
  };

  const handleGroupDelegateChange = (usrGroup: number[]) => {
    if (!task) {
      return;
    }

    if (typeof task.id !== 'undefined') {
      addDelegateGroupToContent(task.id, usrGroup);
    }
  };

  return (
    <AccordionHeader
      id={id}
      as="h4"
      active={widgetOpen}
      title={`${t('CASE_TITLE_DELEGATIONS')}`}
      label={delegates.length}
      icon="add user"
      isWidget={true}
    >
      <Delegates
        autosuggestions={autosuggestions}
        user={user}
        usersList={usersList}
        delegates={task.delegatedTo}
        onAdd={(usr: string) => {
          handleDelegateChange(usr, 'POST');
        }}
        onRemove={(usr: string) => {
          handleDelegateChange(usr, 'DELETE');
        }}
        onGroupAdd={handleGroupDelegateChange}
        ticketType={taskTicketType!} // TODO typing undefined
        userData={userData}
        showOnlyRoles={widget.showOnlyRoles}
      />
    </AccordionHeader>
  );
};

const connector = connect(
  (state: State) => ({
    usersList: state.usersList.usersList,
    user: state.usersList.usersList.find((user) => user.UID === state.userData.UID),
    ticketTypes: state.ticketTypes,
    autosuggestions: state.suggestions.filter((s) => s.type === 'DelegationGroup'),
    userData: state.userData
  }),
  {
    addDelegateToContent,
    removeDelegateFromContent,
    addDelegateGroupToContent
  }
);

export default connector(Delegations);
