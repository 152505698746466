export const TicketDirectionValues = ['in', 'out'] as const;
export type TicketDirection = (typeof TicketDirectionValues)[number];

export type ContentStatus =
  | 'draft'
  | 'inReview'
  | 'waitingToBePublished'
  | 'published'
  | 'waste'
  | 'todo'
  | 'doing'
  | 'done';

export interface UpdateContentPayload {
  title?: string;
  content?: string;
  dueDate?: number;
  originalDueDate?: number;
  taskStatus?: ContentStatus;
  metaData?: Record<string, string>;
  taskType?: string;
  channel?: number;
  active?: 1 | 0;
  priority?: number;
  weight?: number;
  lastEdited: number;
  editedByUserId: number;
}

export interface BatchContentUpdate extends Pick<UpdateContentPayload, 'taskStatus' | 'channel' | 'priority'> {
  id: string;
}
