import { ContentNamingType, convertTicketId } from '@eeedo/utils';
import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DropdownMenu } from 'semantic-ui-react';

import SearchAndCommandPaletteResults from './SearchAndCommandPaletteResults';
import useCommands from './useCommands';
import { activateTab } from 'src/actions/tabActionsRTK';
import { activateTicket } from 'src/actions/ticketsActions';
import Dropdown from 'src/Components/generic/Dropdown/Dropdown';

import './SearchAndCommandPalette.css';

export const SearchAndCommandPalette = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [commandPrompts] = useCommands();

  const onOpenTicket = (value: number | string) => {
    const ticketId = convertTicketId<string>(value, ContentNamingType.String);
    dispatch(activateTicket(ticketId));
    dispatch(activateTab(ticketId));
  };

  const onClearSearch = () => {
    setSearchQuery('');
    setIsOpen(false);
  };

  const onResultSelect = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
    const command = commandPrompts.find((prompt) => prompt.value === data.value);

    if (command) {
      command?.action?.();
    } else if (data.value) {
      onOpenTicket(data.value);
    }

    setSearchQuery('');
  };

  return (
    <Dropdown
      className="command_palette"
      fluid
      noResultsMessage={t('PLACEHOLDER_SEARCH_NO_RESULTS')}
      onChange={onResultSelect}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      onSearchChange={(_, data) => setSearchQuery(data.searchQuery)}
      open={isOpen}
      placeholder={t('SEARCH')}
      search
      searchInput={{ size: 'huge' }}
      searchQuery={searchQuery}
      selectOnBlur={false}
      selectOnNavigation={false}
      selection
      size="large"
      value=""
      icon={
        <>
          <FontAwesomeIcon icon={faMagnifyingGlass} className="icon icon-left" key="search" />
          {!!searchQuery && (
            <FontAwesomeIcon icon={faXmark} className="icon icon-right" onClick={onClearSearch} key="clear" />
          )}
        </>
      }
    >
      <DropdownMenu>
        {isOpen && (
          <SearchAndCommandPaletteResults
            searchQuery={searchQuery}
            onOpenTicket={onOpenTicket}
            onClearSearch={onClearSearch}
          />
        )}
      </DropdownMenu>
    </Dropdown>
  );
};
