import { ChannelType } from '@eeedo/types';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import type { PayloadAction } from '@reduxjs/toolkit';

import {
  defaultOptionsMap,
  dropParameterOption,
  getParameterOptionsLength,
  upsertParameterOption
} from 'src/Utilities/campaign';

import type {
  CampaignConfigRecord,
  CampaignSource,
  CheckedHeaders,
  DateParameterMode,
  ImportResponseTemplate,
  ImportTemplate,
  Parameter,
  ParameterArrayOptionType,
  ParameterType
} from 'src/types/Campaign';
import type { TicketStatus } from 'src/types/TicketList';
import type { RPOArgs, UPOArgs } from 'src/Utilities/campaign';

export interface CampaignManagementState {
  step: number;
  checkedHeaders: CheckedHeaders[];
  importsList: CampaignConfigRecord[];
  templates: ImportTemplate[];
  importFile?: File;
  payload: {
    tags: string[];
    title: string;
    content: string;
    selectedTicketsIds: number[];
    campaignSource: CampaignSource;
    senderEmail: string;
    senderPhone: string;
    channel: ChannelType;
    scheduled?: number | null;
    templateId?: number;
    recordsAmount?: number;
    fileId?: number;
    responseTemplates?: ImportResponseTemplate[];
    parameters: Parameter[];
    ticketType: string | null;
    ticketStatus: TicketStatus;
    ticketDueDate: number;
  };
  dateParameters: Record<number, Record<number, DateParameterMode>>;
}

const initialState: CampaignManagementState = {
  step: 1,
  templates: [],
  importsList: [],
  checkedHeaders: [],
  dateParameters: {},
  payload: {
    tags: [],
    title: '',
    content: '',
    parameters: [],
    senderEmail: '',
    senderPhone: '',
    selectedTicketsIds: [],
    channel: ChannelType.Email,
    campaignSource: 'customer',
    ticketType: null,
    ticketStatus: 'todo',
    ticketDueDate: moment().unix()
  }
};

const campaignSlice = createSlice({
  name: 'campaignSlice',
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setImportsList: (state, action: PayloadAction<CampaignConfigRecord[]>) => {
      state.importsList = action.payload;
    },
    addToImportList: (state, action: PayloadAction<CampaignConfigRecord>) => {
      if (!state.importsList.find((il) => il.id === action.payload.id)) {
        state.importsList.push(action.payload);
      }
    },
    updateDateParametersModes: (
      state,
      action: PayloadAction<{ parameterIndex: number; optionIndex: number; mode: DateParameterMode | undefined }>
    ) => {
      const { parameterIndex, optionIndex, mode } = action.payload;
      if (!state.dateParameters[parameterIndex]) {
        state.dateParameters[parameterIndex] = {};
      }

      if (mode) {
        state.dateParameters[parameterIndex][optionIndex] = mode;
      } else {
        delete state.dateParameters[parameterIndex][optionIndex];
      }
    },
    updateImportsList: (state, action: PayloadAction<{ importConfig: CampaignConfigRecord; newRecord: boolean }>) => {
      const { newRecord, importConfig } = action.payload;

      if (newRecord) {
        state.importsList.push(action.payload.importConfig);
      } else {
        const index = state.importsList.findIndex((il) => il.id === importConfig.id);
        state.importsList[index] = action.payload.importConfig;
      }
    },
    setImportFile: (state, action: PayloadAction<File>) => {
      state.importFile = action.payload;
    },
    setCheckedHeaders: (state, action: PayloadAction<CheckedHeaders[]>) => {
      state.checkedHeaders = action.payload;
    },
    setCampaignPayloadProp: (state, action: PayloadAction<Partial<CampaignManagementState['payload']>>) => {
      state.payload = { ...state.payload, ...action.payload };
    },
    addParameter: (state, action: PayloadAction<Parameter>) => {
      if (!state.payload.parameters?.length) {
        state.payload.parameters = [];
      }

      state.payload.parameters.push(action.payload);
    },
    updateParameter: (state, action: PayloadAction<{ prop: keyof Parameter; value: any; index: number }>) => {
      if (!state.payload.parameters?.length) {
        state.payload.parameters = [];
      }

      if (state.payload.parameters[action.payload.index]) {
        state.payload.parameters[action.payload.index][action.payload.prop] = action.payload.value;
      }
    },
    changeParameterType: (state, action: PayloadAction<{ type: ParameterType; index: number }>) => {
      const { index, type } = action.payload;

      if (state.payload.parameters?.[index]) {
        state.payload.parameters[index] = {
          type,
          path: '',
          data: typeof defaultOptionsMap[type] !== 'undefined' ? [] : {}
        };
      }
    },
    removeParameter: (state, action: PayloadAction<number>) => ({
      ...state,
      payload: {
        ...state.payload,
        parameters: state.payload.parameters?.filter((_p, index) => action.payload !== index)
      }
    }),
    addParamaterOption: (state, action: PayloadAction<{ parameterIndex: number; type: ParameterArrayOptionType }>) => {
      const { parameterIndex, type } = action.payload;
      const optionIndex = getParameterOptionsLength(parameterIndex, type)(state);
      const option = defaultOptionsMap[type];

      return upsertParameterOption({ parameterIndex, optionIndex, type, option })(state);
    },
    updateParameterOption: (state, action: PayloadAction<UPOArgs<ParameterArrayOptionType>>) =>
      upsertParameterOption(action.payload)(state),
    removeParameterOption: (state, action: PayloadAction<RPOArgs>) => dropParameterOption(action.payload)(state),
    addCampaignResponseTemplate: (state, action: PayloadAction<ImportResponseTemplate>) => {
      if (!state.payload.responseTemplates?.length) {
        state.payload.responseTemplates = [];
      }

      state.payload.responseTemplates.push(action.payload);
    },
    updateCampaignResponseTemplateProp: (
      state,
      action: PayloadAction<{
        id: string;
        prop: keyof ImportResponseTemplate;
        value: any;
      }>
    ) => {
      const { id, prop, value } = action.payload;
      const templateIndex = state.payload.responseTemplates?.findIndex((t) => t._id === id);

      if (state.payload.responseTemplates?.length && typeof templateIndex === 'number') {
        state.payload.responseTemplates[templateIndex][prop] = value;
      }
    },
    removeCampaignResponseTemplate: (state, action: PayloadAction<string>) => {
      state.payload.responseTemplates = state.payload.responseTemplates?.filter((t) => t._id !== action.payload);
    },
    fetchCampaignTemplatesSuccess: (state, action: PayloadAction<ImportTemplate[]>) => {
      state.templates = action.payload;
    },
    resetCampaign: (state) => ({ ...initialState, templates: state.templates, importsList: state.importsList })
  }
});

export const {
  setStep,
  addParameter,
  setImportFile,
  resetCampaign,
  setImportsList,
  updateParameter,
  removeParameter,
  addToImportList,
  updateImportsList,
  setCheckedHeaders,
  addParamaterOption,
  changeParameterType,
  updateParameterOption,
  removeParameterOption,
  setCampaignPayloadProp,
  updateDateParametersModes,
  addCampaignResponseTemplate,
  fetchCampaignTemplatesSuccess,
  removeCampaignResponseTemplate,
  updateCampaignResponseTemplateProp
} = campaignSlice.actions;

export default campaignSlice.reducer;
