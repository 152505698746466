import { FilterPresetView } from '@eeedo/types';
import { orderBy } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import type { FilterPresetExtended } from '@eeedo/types';

import { setActiveFilterPreset } from 'src/actions/filterPresetsActions';
import { clearInfopagelistFilter, setInfopagelistFilter } from 'src/actions/infoPagesActionsRTK';
import { clearTicketlistFilter, setTicketlistFilter, setTicketlistSorting } from 'src/actions/ticketListTabActionsRTK';
import { useAppSelector } from 'src/store';
import { filterTicketList } from 'src/Utilities/ticketList';

import type { MenuTab } from 'src/types/MenuTab';

type UseFilterPresets = (
  clearFilters?: () => void
) => [FilterPresetExtended[], number | null | undefined, (id: number) => void];

const useFilterPresets: UseFilterPresets = (clearFilters) => {
  const dispatch = useDispatch();
  const categories = useAppSelector((state) => state.categories);
  const isInfopage = useAppSelector((state) => state.router.location.pathname.startsWith('/infopage'));
  const userData = useAppSelector((state) => state.userData);
  const usersList = useAppSelector((state) => state.usersList.usersList);
  const { filterPresets, activeId } = useAppSelector((state) => state.filterPresets);
  const { defaultFilterPreset } = userData.userPreferences;

  const activeTabId = useAppSelector((state) => {
    const tabs: MenuTab[] = isInfopage ? [...state.infoPageListTabs.values()] : Object.values(state.ticketListTabs);
    return tabs.find((t) => t.activeTab)?.id ?? '';
  });

  const contents = useAppSelector((state) =>
    isInfopage ? state.infoPageListTabs[activeTabId]?.tickets : state.ticketListTabs[activeTabId]?.tickets
  );

  const currentView = useAppSelector((state) =>
    state.router.location.pathname.startsWith('/infopage') ? FilterPresetView.infopage : FilterPresetView.main
  );

  const filterPresetsExtended: FilterPresetExtended[] = useMemo(() => {
    return filterPresets
      .filter(
        (filterPreset) =>
          filterPreset.ticketTypes.length === 0 ||
          filterPreset.ticketTypes.some((filterSetTicketType) => userData.ticketTypes.includes(filterSetTicketType))
      )
      .map((filterPreset) => {
        const counts = filterTicketList({
          categories,
          tickets: contents ?? [],
          personalData: userData,
          filters: filterPreset.filters,
          usersList
        }).reduce<FilterPresetExtended['counts']>((acc, { status }) => {
          if (status) {
            acc[status] = (acc[status] ?? 0) + 1;
          }
          return acc;
        }, {});

        return {
          ...filterPreset,
          isDefault: filterPreset.id === defaultFilterPreset,
          isSelected: filterPreset.id === activeId,
          counts
        };
      });
  }, [categories, contents, userData, filterPresets, usersList, defaultFilterPreset, activeId]);

  const sortedFilterPresets = useMemo(
    () => orderBy(filterPresetsExtended, ['isDefault', 'isFavorite', 'name'], ['desc', 'desc', 'asc']),
    [filterPresetsExtended]
  );

  const applyFilter = useCallback(
    (id: number) => {
      const filter = sortedFilterPresets.find((f) => f.id === id);
      if (!filter) {
        throw new Error('Something went wrong');
      }

      if (!activeTabId) {
        return;
      }

      const setFilter = currentView === FilterPresetView.infopage ? setInfopagelistFilter : setTicketlistFilter;
      const setSorting = currentView === FilterPresetView.infopage ? null : setTicketlistSorting;

      dispatch(setActiveFilterPreset({ filterPresetId: filter.id }));

      if (clearFilters) {
        clearFilters();
      } else {
        dispatch(
          (currentView === FilterPresetView.infopage ? clearInfopagelistFilter : clearTicketlistFilter)(activeTabId)
        );
      }

      if (filter.isSelected) {
        dispatch(setActiveFilterPreset({ filterPresetId: null }));
        return;
      }

      dispatch(setActiveFilterPreset({ filterPresetId: filter.id }));

      Object.keys(filter?.filters).forEach((filterKey) => {
        dispatch(
          setFilter({
            id: activeTabId,
            value: filter?.filters[filterKey],
            parameter: filterKey as any
          })
        );
      });

      if (setSorting) {
        dispatch(setSorting(filter.sorting, filter.direction));
      }
    },
    [dispatch, sortedFilterPresets, activeTabId, clearFilters]
  );

  return [sortedFilterPresets, defaultFilterPreset, applyFilter];
};

export default useFilterPresets;
