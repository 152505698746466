import { faTableLayout } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSingleLineTabContext } from './SingleLineTabProvider';
import Button from 'src/Components/generic/Button/Button';
import Card from 'src/Components/generic/Card/Card';
import Modal from 'src/Components/generic/Modal/Modal';
import Section from 'src/Components/generic/Section/Section';
import { DragHandle, SortableItem } from 'src/Components/generic/SortableList/SortableItem';
import { SortableList } from 'src/Components/generic/SortableList/SortableList';
import Toggle from 'src/Components/generic/Toggle/Toggle';

import type { Tab } from './SingleLineTabProvider';

function TableTabManage() {
  const { t } = useTranslation();
  const { tabsData, setTabsData, resetTabs } = useSingleLineTabContext();

  const [itemsState, setItems] = useState<Tab[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  const handleSetTabData = (newTabsData: Tab) => {
    if (itemsState) setItems(itemsState.map((item) => (item.id === newTabsData.id ? newTabsData : item)));
  };

  const handleRestTabs = useCallback(() => {
    resetTabs();
    setModalOpen(false);
  }, [resetTabs]);

  const handleCancel = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const handleSaveTabs = useCallback(() => {
    if (itemsState) setTabsData(itemsState);
    setModalOpen(false);
  }, [itemsState, setTabsData, setModalOpen]);

  useEffect(() => {
    setItems(tabsData);
  }, []);

  useEffect(() => {
    setItems(tabsData);
  }, [tabsData]);

  return (
    <Modal
      scrolling
      size="tiny"
      open={modalOpen}
      onOpen={() => setModalOpen(true)}
      onClose={() => setModalOpen(false)}
      header={
        <Section direction="column" gap={8}>
          <Section direction="row" gap={8} align="center">
            <FontAwesomeIcon icon={faTableLayout} size="lg" color="rgba(127, 86, 218, 1)" />
            <h3 style={{ margin: 0 }}>{t('MANAGE_COLUMNS')}</h3>
          </Section>
          <h6 style={{ margin: 0, fontWeight: 400 }}>{t('MANAGE_COLUMNS_DESC')}</h6>
        </Section>
      }
      trigger={
        <Button iconLeft={<FontAwesomeIcon icon={faTableLayout} />} type="normal">
          {t('MANAGE_COLUMNS')}
        </Button>
      }
      footer={
        <Section justify="space-between" gap={8}>
          <Button onClick={() => handleRestTabs()}>{t('GENERAL_RESET_TO_DEFAULT')}</Button>
          <Section gap={8}>
            <Button onClick={() => handleCancel()}>{t('GENERAL_CLOSE')}</Button>
            <Button type="primary" onClick={() => handleSaveTabs()}>
              {t('GENERAL_SAVE')}
            </Button>
          </Section>
        </Section>
      }
    >
      <SortableList
        items={itemsState}
        renderItem={(item) => (
          <SortableItem id={item.id}>
            <Card fluid>
              <Section direction="row" gap={8} justify="space-between" align="center">
                <Section gap={8} align="center">
                  <DragHandle /> {t(item.title)}
                </Section>
                <Toggle
                  checked={item.show}
                  onChange={(_, data) => handleSetTabData({ ...item, show: !!data.checked })}
                />
              </Section>
            </Card>
          </SortableItem>
        )}
        onChange={setItems}
      />
    </Modal>
  );
}

export default React.memo(TableTabManage);
