import { faArrowUpRightFromSquare, faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DropdownItem } from 'semantic-ui-react';

import type { Moment } from 'moment';
import type { FC } from 'react';

import SearchAndCommandPaletteBox from './SearchAndCommandPaletteBox';
import SearchAndCommandPaletteFilters from './SearchAndCommandPaletteFilters';
import useSearch from './useSearch';
import { fireTicketSearch } from 'src/actions/ticketsActions';
import { DATE_FORMAT } from 'src/Utilities/dates';
import { getSearchFieldTranslation } from 'src/Utilities/search';

import type { SearchCriterion } from 'src/types/Search';

const TICKET_RESULTS = 5;

interface Props {
  searchQuery: string;
  onOpenTicket: (ticketId: string | number) => void;
  onClearSearch: () => void;
}

const SearchAndCommandPaletteResults: FC<Props> = ({ searchQuery, onOpenTicket, onClearSearch }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedTag, setSelectedTag] = useState<string | undefined>(undefined);
  const [selectedTicketType, setSelectedTicketType] = useState<string | undefined>(undefined);
  const [matchedSearches, searchResults] = useSearch(searchQuery, { selectedTag, selectedTicketType });
  const {
    matchedFromTicketList,
    matchedFromOpenTickets,
    matchedCommandPrompts,
    matchedCreatedFromTicketList,
    matchedDueDateFromTicketList
  } = searchResults;

  const onSearch = (value: string, param: SearchCriterion['param']) => {
    const name: string = getSearchFieldTranslation(param, t);
    dispatch(
      fireTicketSearch({
        searchCriteria: [{ datagroup: 'basic', param, value, name }]
      })
    );
    onClearSearch();
  };

  const onSearchByDate = (
    date: Moment,
    paramAfter: SearchCriterion['param'],
    paramBefore: SearchCriterion['param']
  ) => {
    dispatch(
      fireTicketSearch({
        searchCriteria: [
          {
            datagroup: 'basic',
            param: paramAfter,
            value: date.startOf('day').unix(),
            text: date.startOf('day').toString(),
            name: getSearchFieldTranslation(paramAfter, t)
          },
          {
            datagroup: 'basic',
            param: paramBefore,
            value: date.endOf('day').unix(),
            text: date.endOf('day').toString(),
            name: getSearchFieldTranslation(paramBefore, t)
          }
        ]
      })
    );
    onClearSearch();
  };

  return (
    <>
      <SearchAndCommandPaletteFilters
        selectedTag={selectedTag}
        setSelectedTag={setSelectedTag}
        selectedTicketType={selectedTicketType}
        setSelectedTicketType={setSelectedTicketType}
      />

      {!!matchedSearches.date && (
        <>
          <DropdownItem onClick={() => onSearchByDate(matchedSearches.date!, 'duedateAfter', 'duedateBefore')}>
            <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
            {t('search_and_command_palette.search_tickets_with_duedate_on')}:{' '}
            <strong>{matchedSearches.date.format(DATE_FORMAT)}</strong>
          </DropdownItem>
          <DropdownItem onClick={() => onSearchByDate(matchedSearches.date!, 'createdAfter', 'createdBefore')}>
            <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
            {t('search_and_command_palette.search_tickets_with_created_on')}:{' '}
            <strong>{matchedSearches.date.format(DATE_FORMAT)}</strong>
          </DropdownItem>
        </>
      )}

      {!!matchedSearches.ticketId && (
        <DropdownItem onClick={() => onOpenTicket(matchedSearches.ticketId!)}>
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="icon" />
          {t('search_and_command_palette.open_ticket')}: <strong>{matchedSearches.ticketId}</strong>
        </DropdownItem>
      )}

      {!!matchedSearches.email && (
        <>
          <DropdownItem onClick={() => onSearch(matchedSearches.email!, 'originalContactSearch')}>
            <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
            {t('search_and_command_palette.search_tickets_by_original_contact')}:{' '}
            <strong>{matchedSearches.email}</strong>
          </DropdownItem>
          <DropdownItem onClick={() => onSearch(matchedSearches.email!, 'lastContactAddress')}>
            <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
            {t('search_and_command_palette.search_tickets_by_last_contact')}: <strong>{matchedSearches.email}</strong>
          </DropdownItem>
          <DropdownItem onClick={() => onSearch(matchedSearches.email!, 'emailTo')}>
            <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
            {t('search_and_command_palette.search_tickets_by_recipient')}: <strong>{matchedSearches.email}</strong>
          </DropdownItem>
        </>
      )}

      {!!matchedSearches.phoneNumber && (
        <>
          <DropdownItem onClick={() => onSearch(matchedSearches.phoneNumber!, 'originalContactSearch')}>
            <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
            {t('search_and_command_palette.search_tickets_by_original_contact')}:{' '}
            <strong>{matchedSearches.phoneNumber}</strong>
          </DropdownItem>
          <DropdownItem onClick={() => onSearch(matchedSearches.phoneNumber!, 'lastContactAddress')}>
            <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
            {t('search_and_command_palette.search_tickets_by_last_contact')}:{' '}
            <strong>{matchedSearches.phoneNumber}</strong>
          </DropdownItem>
        </>
      )}

      {!!matchedSearches.text && !matchedSearches.date && (
        <>
          <DropdownItem onClick={() => onSearch(matchedSearches.text!, 'titleSearch')}>
            <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
            {t('search_and_command_palette.search_tickets_by_title')}: <strong>{matchedSearches.text}</strong>
          </DropdownItem>
          <DropdownItem onClick={() => onSearch(matchedSearches.text!, 'selectedTicketWords')}>
            <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
            {t('search_and_command_palette.search_tickets_by_content')}: <strong>{matchedSearches.text}</strong>
          </DropdownItem>
        </>
      )}

      <div className="command_palette_box_wrapper">
        {!!matchedCreatedFromTicketList.length && !!matchedSearches.date && (
          <SearchAndCommandPaletteBox
            title={`${t('search_and_command_palette.from_ticket_list_with_created_on')}: ${matchedSearches.date.format(
              DATE_FORMAT
            )}`}
            fluid={!matchedDueDateFromTicketList.length}
            results={matchedCreatedFromTicketList.length}
            tickets={matchedCreatedFromTicketList.slice(0, TICKET_RESULTS)}
          />
        )}

        {!!matchedDueDateFromTicketList.length && !!matchedSearches.date && (
          <SearchAndCommandPaletteBox
            title={`${t('search_and_command_palette.from_ticket_list_with_duedate_on')}: ${matchedSearches.date.format(
              DATE_FORMAT
            )}`}
            fluid={!matchedCreatedFromTicketList.length}
            results={matchedDueDateFromTicketList.length}
            tickets={matchedDueDateFromTicketList.slice(0, TICKET_RESULTS)}
          />
        )}

        {!!matchedFromTicketList.length && (
          <SearchAndCommandPaletteBox
            title={t('search_and_command_palette.from_ticket_list')}
            results={matchedFromTicketList.length}
            fluid={!matchedFromOpenTickets.length}
            tickets={matchedFromTicketList.slice(0, TICKET_RESULTS)}
          />
        )}

        {!!matchedFromOpenTickets.length && (
          <SearchAndCommandPaletteBox
            title={t('search_and_command_palette.from_opened_tickets')}
            results={matchedFromOpenTickets.length}
            tickets={matchedFromOpenTickets.slice(0, TICKET_RESULTS)}
          />
        )}

        {!!matchedCommandPrompts.length && (
          <SearchAndCommandPaletteBox title={t('Commands')} commands={matchedCommandPrompts} fluid />
        )}
      </div>

      {!matchedFromTicketList.length && !matchedFromOpenTickets.length && !matchedCommandPrompts.length && (
        <DropdownItem disabled>{t('PLACEHOLDER_SEARCH_NO_RESULTS')}</DropdownItem>
      )}
    </>
  );
};

export default SearchAndCommandPaletteResults;
