import { ContentNamingType, convertTicketId } from '@eeedo/utils';
import React from 'react';
import { useDispatch } from 'react-redux';
import { DropdownItem, Label } from 'semantic-ui-react';

import type { FC } from 'react';

import { activateTab } from 'src/actions/tabActionsRTK';
import { activateTicket } from 'src/actions/ticketsActions';

import type { TicketSearch } from './types';
import type { CommandPrompt } from './useCommands';

interface Props {
  ticket?: TicketSearch;
  promptOption?: CommandPrompt;
}

const SearchAndCommandPaletteItem: FC<Props> = ({ ticket, promptOption }) => {
  const dispatch = useDispatch();

  const onOpenTicket = (value: number | string) => {
    const ticketId = convertTicketId<string>(value, ContentNamingType.String);
    dispatch(activateTicket(ticketId));
    dispatch(activateTab(ticketId));
  };

  if (ticket) {
    return (
      <DropdownItem onClick={() => onOpenTicket(ticket.id)}>
        <Label content={ticket.id} />
        {ticket.title}
      </DropdownItem>
    );
  }

  if (promptOption) {
    return (
      <DropdownItem>
        <Label content=">" />
        {promptOption.text}
        <div style={{ float: 'right' }}>
          {promptOption.shortcut && (
            <span style={{ float: 'right' }}>
              {promptOption.shortcut.split(',').map((k) => (
                <Label className="command-palette-list-label-border" content={k} key={k} />
              ))}
            </span>
          )}
        </div>
      </DropdownItem>
    );
  }

  return null;
};

export default SearchAndCommandPaletteItem;
