import { connect } from 'react-redux';

import { activateContentListTab } from '../actions/CaseListActions';
import { fetchInfoPages } from '../actions/infoPagesActions';
import TabBar from '../TabBar';
import { closeContentListTab } from 'src/actions/ticketListTabActionsRTK';
import { StaticTabs } from 'src/types/TicketList';

import type { State } from 'src/types/initialState';
import type { ThunkAppDispatch } from 'src/types/store';
import type { TabBarMethod } from 'src/types/TabBar';

const mapStateToProps = (state: State) => {
  return {
    mainTab: state.infoPageListTabs.get(StaticTabs.MAIN_VIEW),
    tabs: [...state.infoPageListTabs.values()].filter((t) => t.id !== StaticTabs.MAIN_VIEW),
    personalData: state.userData,
    ticketTypes: state.ticketTypes
  };
};

const mapDispatchToProps = (dispatch: ThunkAppDispatch) => {
  return {
    onTabClose: (id: string, activeId: string) => {
      if (activeId !== '-1') {
        dispatch(activateContentListTab(activeId, 'infopages'));
      }
      dispatch(closeContentListTab(id, 'infopages'));
    },
    onActivateTab: (id: string) => {
      dispatch(activateContentListTab(id, 'infopages'));
    },
    scrollCaseBottomInstantly: () => {
      // No scrolling for info page list
    },
    method: 'contentLists' as TabBarMethod,
    refreshMainView: () => {
      dispatch(fetchInfoPages({ id: StaticTabs.MAIN_VIEW, throughSearchTab: false }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabBar);
