import { faClock, faPencil, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';

import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { FC } from 'react';
import type { PopupProps } from 'semantic-ui-react';

import { DATE_TIME_FORMAT, getPrettyDate } from '../../../../Utilities/dates';
import { getIconTranslationString } from '../../../CommentIconContent/CommentIconContent.utils';
import CommentHeader from '../../../generic/CommentHeader/CommentHeader';
import UserAvatar from '../../../User/UserAvatar';
import { getProfileName, getUserById } from '../../../User/utils';
import styles from './TopBarLabelList.module.css';

import type { State } from '../../../../types/initialState';
import type { Ticket } from '../../../../types/Ticket';

interface Props {
  task: Ticket;
  showWeight?: boolean;

  dueDateChange: (newDueDate: number) => void;
  updateTicket(id: string, data: Partial<Ticket>): void;
}

const popupDefaultProps: PopupProps = {
  flowing: true,
  hoverable: true,
  position: 'bottom center',
  wide: true
};

const getFormattedDate = (date: number | string) => getPrettyDate(date, { format: DATE_TIME_FORMAT });

const TopBarLabelList: FC<Props> = ({
  task
  // showWeight,
  // dueDateChange,
  // updateTicket
}) => {
  const { t } = useTranslation();
  // const channels = useSelector((state: State) => state.channels);
  // const userData = useSelector((state: State) => state.userData);
  // const [weight, setWeight] = useState(task?.weight || 0);
  // const [isEditingWeight, setIsEditingWeight] = useState(false);
  const users = useSelector((state: State) => state.usersList.usersList);
  const user = getUserById(users, task.createdByUser);
  const name = getProfileName(user);

  const dateCreated = getFormattedDate(task?.created);
  const dateTouched = getFormattedDate(task?.touched);
  const dateDue = getFormattedDate(task?.dueDate);

  const renderDateItem = (value: string, translationKey: string, icon: IconProp) => (
    <div className={styles.topBarDatesItem}>
      <Popup
        {...popupDefaultProps}
        content={
          <div>
            <b>{value}</b>
            <br />
            {t(translationKey)}
          </div>
        }
        trigger={
          <div className={styles.topBarDatesTrigger}>
            <FontAwesomeIcon className={styles.topBarDatesItemIcon} icon={icon} />
            <span>{value}</span>
          </div>
        }
      />
    </div>
  );

  if (!task) {
    return null;
  }

  return (
    <div className={styles.topBarLabelListContainer}>
      <CommentHeader
        avatar={<UserAvatar UID={task.createdByUser} size="40" round />}
        name={name}
        extra={`via ${t(getIconTranslationString(task.channel))}`}
        description={task.lastContactAddress ? `To: ${task.lastContactAddress}` : ''}
        appearence="ticketHeader"
      />

      <div className={styles.topBarDates}>
        {renderDateItem(dateCreated, 'TOOLTIP_CREATED', faPlus)}
        {renderDateItem(dateTouched, 'TOOLTIP_TOUCHED', faPencil)}
        {renderDateItem(dateDue, 'TOOLTIP_DUEDATE_SHORT', faClock)}
        {/* FIXME: fix actual dueDate picker*/}
        {/* {false && (
          <div className={styles.topBarDatesItem}>
            <DueDatePicker
              disabled={!userData.permissions.includes('updateContent')}
              dueDate={task.dueDate}
              changeDueDate={(value: number) => {
                iziToast.success({
                  title: t('TICKET_DUEDATE_SET'),
                  message: getFormattedDate(value!),
                  icon: 'icon check'
                });
                dueDateChange(value);
              }}
            />
          </div>
        )}*/}
      </div>

      {/* {false && (
        <>
          <List className="topBarLabelList" horizontal divided>
            {channels && 'originalDirection' in task && (
              <Popup
                disabled
                position="bottom center"
                trigger={
                  <List.Item>
                    <Label>
                      <Icon
                        name={iconName(
                          channels.find((c) => c.id === task.channel),
                          task.originalDirection
                        )}
                      />
                      {t(`TICKET_DIRECTION_${task.originalDirection.toUpperCase()}`)}
                    </Label>
                  </List.Item>
                }
              />
            )}

            {showWeight && (
              <WeightModal
                weight={weight}
                onChange={(value) => {
                  setWeight(value);
                  if (task) {
                    updateTicket(task.id, {
                      weight: value
                    });
                  }
                }}
                open={isEditingWeight}
                setOpen={setIsEditingWeight}
              />
            )}
          </List>
        </>
      ) 
      }*/}
    </div>
  );
};

export default TopBarLabelList;
