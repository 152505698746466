import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Comment, Icon, Popup } from 'semantic-ui-react';

import type { Channel, Priority, Tag, TicketType, UserWithProfile } from '@eeedo/types';
import type { FC } from 'react';
import type { SemanticICONS } from 'semantic-ui-react';

import { translateComment, translateQuarantine } from './utils';
import ChannelType from 'src/Components/CommentIconContent/ChannelType';
import CommentIconContent from 'src/Components/CommentIconContent/CommentIconContent';
import CommentHeader from 'src/Components/generic/CommentHeader/CommentHeader';
import ErrorBoundary from 'src/ErrorBoundary';
import { CommentMetadataType } from 'src/types/Ticket';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import { getPrefixByType } from 'src/Utilities/helper';

import type { ContentTypes } from 'src/types/ContentTypes';
import type { Attachment, Comment as TicketComment } from 'src/types/Ticket';

interface CommentProps extends Partial<TicketComment> {
  attachments: Attachment[];
  channels: Channel[];
  compact?: boolean;
  contentType?: ContentTypes;
  priorities: Priority[];
  tags: Tag[];
  ticketTypes: TicketType[];
  user?: UserWithProfile;
  usersData: UserWithProfile[];
}

const AutomaticComment: FC<CommentProps> = ({
  attachments,
  channel,
  channels,
  compact,
  content,
  contentType,
  created,
  metaData,
  priorities,
  tags,
  ticketTypes,
  title,
  user,
  usersData
}) => {
  const { t } = useTranslation();

  const channelTypeName =
    {
      [ChannelType.Email]: 'email',
      [ChannelType.Sms]: 'sms',
      [ChannelType.Internal]: 'internal',
      [ChannelType.Chat]: 'chat'
    }[channel ?? 0] ?? 'other';

  const commentContent = useMemo(() => {
    if (!content && metaData) {
      const { type, previousValue, value, mainContentId, mergedContents } = metaData;
      switch (type) {
        case CommentMetadataType.ticketType: {
          const previousTicketType = ticketTypes.find((ticketType) => ticketType.id == previousValue) || {
            name: `(${t('INACCESSIBLE')})`
          };
          const currentTicketType = ticketTypes.find((ticketType) => ticketType.id == value) || {
            name: `(${t('INACCESSIBLE')})`
          };
          return `${t('CHANGED')} ${type}: ${previousTicketType.name} → ${currentTicketType.name}`;
        }
        case CommentMetadataType.ticketPriority: {
          const priority = priorities.find((priority) => priority.value === value);
          return `${t('AUTOMATIC_COMMENT_PRIORITY_SET_TO')}: ${priority ? t(priority.text) : value}`;
        }
        case CommentMetadataType.merge: {
          const prefix = getPrefixByType(contentType);
          return mergedContents?.length
            ? `${t('CONTENTS_MERGED')} ${mergedContents.map((c) => `${prefix}${c}`).join(', ')}`
            : `${t('CONTENT_IS_MERGED_INTO')} ${prefix}${mainContentId}`;
        }
        case CommentMetadataType.unansweredPhoneCall: {
          const { phone, duration, acdName } = metaData;
          return `${t('AUTOMATIC_COMMENT_UNANSWERED_CALL_FROM', { phoneNumber: phone, duration, acdName })}`;
        }
        case CommentMetadataType.openExternalLink: {
          return `${t('AUTOMATIC_COMMENT_OPEN_EXTERNAL_LINK', { link: value })}`;
        }
        default:
          return;
      }
    }

    const keys: { [key: string]: (c: string) => string | undefined } = {
      '[call_response_time:': (c) => translateComment(c, 'CALL_RESPONSE_TIME', t),
      '[status_start_working_on:': (c) => translateComment(c, 'START', t, { usersData }),
      '[status_stop_working_on:': (c) => translateComment(c, 'STOP', t, { usersData }),
      '[delegated_task_to]': (c) => translateComment(c, 'DELEGATED_TASK_TO', t) + c,
      '[delegation_removed_from]': (c) => translateComment(c, 'DELEGATION_REMOVED_FROM', t) + c,
      '[new_task_status_is]': (c) => translateComment(c, 'NEW_TASK_STATUS_IS', t),
      '[marked_as_quarantined:': (c) => translateQuarantine(c, true, attachments, t),
      '[marked_as_unquarantined:': (c) => translateQuarantine(c, false, attachments, t),
      '[added_attachment]': (c) => translateComment(c, 'ADDED_ATTACHMENT', t) + c,
      '[comment_attached_previously_served_customer:': (c) =>
        translateComment(c, 'ATTACHED_PREVIOUSLY_SERVED_CUSTOMER', t),
      '[status_replace_working_on:': (c) => translateComment(c, 'REPLACE', t, { usersData }),
      '[customer added to the task]': (c) => translateComment(c, 'ATTACHED_CUSTOMER', t) + c,
      '[customer removed from the task]': (c) => translateComment(c, 'DETACHED_CUSTOMER', t) + c,
      '[customer_portal_customer_read_the_message]': (c) => translateComment(c, 'TICKET_READ_BY_CUSTOMER', t) + c,
      '[edited_content]': (c) => translateComment(c, 'EDITED_CONTENT', t),
      '[applied_rule]': (c) => translateComment(c, 'APPLIED_RULE', t),
      '[applied_rule always_run]': (c) => translateComment(c, 'APPLIED_ALWAYSRUN_RULE', t),
      '[entity_verify:': (c) => translateComment(c, 'VERIFY', t, { usersData }),
      '[entity_verify_open:': (c) => translateComment(c, 'VERIFY_OPEN', t, { usersData }),
      '[VERIFIED_FIELDS:': (c) => translateComment(c, 'VERIFIED_FIELDS', t),
      '[original_contact_changed:': (c) => translateComment(c, 'ORIGINAL_CONTACT_CHANGED', t),
      '[tag_added:': (c) => translateComment(c, 'TAG_ADDED', t, { usersData, tags }),
      '[tag_removed:': (c) => translateComment(c, 'TAG_REMOVED', t, { usersData, tags }),
      '[BOT_RESPONSE_PATCH_ERROR:': (c) => translateComment(c, 'BOT_RESPONSE_PATCH_ERROR', t),
      '[BOT_RESPONSE_COMMENT_ERROR]': (c) => translateComment(c, 'BOT_RESPONSE_COMMENT_ERROR', t),
      '[BOT_RESPONSE_FAILED_BECAUSE_CHAT_IS_CLOSED_COMMENT_ERROR]': (c) =>
        translateComment(c, 'BOT_RESPONSE_FAILED_BECAUSE_CHAT_IS_CLOSED_COMMENT_ERROR', t),
      '[eidentification_verified:': (c) => translateComment(c, 'EIDENTIFICATION_VERIFIED', t),
      '[unanswered_call_from:': (c) => translateComment(c, 'UNANSWERED_CALL_FROM', t),
      '[WEBHOOK_SUCCESS]': (c) => translateComment(c, 'WEBHOOK_SUCCESS', t, { metaData }),
      '[WEBHOOK_FAIL]': (c) => translateComment(c, 'WEBHOOK_FAIL', t, { metaData }),
      '[WEBHOOK_FAIL_NEXT_RETRY]': (c) => translateComment(c, 'WEBHOOK_FAIL_NEXT_RETRY', t, { metaData }),
      '[WEBHOOK_FAIL_LAST_RETRY]': (c) => translateComment(c, 'WEBHOOK_FAIL_LAST_RETRY', t, { metaData }),
      '[RULES_ACTION_APPLIED]': (c) => translateComment(c, 'RULES_ACTION_APPLIED', t, { metaData })
    };

    for (const entry of Object.entries(keys)) {
      const key = entry[0];
      const func = entry[1];

      if (content?.startsWith(key)) {
        const slice = content.substring(key.length);
        return func(slice);
      }
    }

    return content;
  }, [attachments, content, contentType, metaData, priorities, tags, ticketTypes, usersData, t]);

  const channelData = useMemo(() => {
    const ch = channels.find((c: Channel) => c.id === channel);
    return ch !== undefined ? ch : undefined;
  }, [channel, channels]);

  const semanticIcon = useMemo((): SemanticICONS | undefined => {
    if (!channelData) {
      return;
    }

    switch (channelData?.icon) {
      case 'email':
        return 'mail';
      case 'note':
        return 'sticky note';
      default:
        return channelData.icon as SemanticICONS;
    }
  }, [channelData]);

  const userDisplayName = useMemo(() => {
    const firstName = user?.profile?.firstName ?? '';
    const lastName = user?.profile?.lastName ?? '';
    return `${firstName} ${lastName}`;
  }, [user]);

  const date = useMemo(() => getPrettyDate(created, { format: DATE_TIME_FORMAT }), [created]);

  return (
    <div className={classNames('automaticComment__container', compact && 'automaticComment__container--compact')}>
      <CommentHeader
        avatar={<FontAwesomeIcon icon={faCircleExclamation} size="3x" width={40} />}
        name={userDisplayName}
        date={date}
        compact={compact}
        extra={
          <div className={channelTypeName || 'other CommentIcon'}>
            <Popup
              className="commentContentPopup"
              position="top center"
              trigger={
                <i>
                  <Icon style={{ color: channelData?.color ?? '' }} name={semanticIcon ?? 'question'} />
                </i>
              }
              content={
                <ErrorBoundary>
                  <CommentIconContent
                    channels={channels}
                    ticketTypes={ticketTypes}
                    channel={channel!}
                    title={title!}
                    metaData={metaData!}
                  />
                </ErrorBoundary>
              }
              on={'hover'}
              hoverable={true}
            />
          </div>
        }
        description={compact ? commentContent : ''}
      />

      {!compact && (
        <Comment.Content style={{ paddingBottom: '24px' }}>
          <Comment.Text>
            <em>{commentContent}</em>
          </Comment.Text>
        </Comment.Content>
      )}
    </div>
  );
};

export default React.memo(AutomaticComment);
