import { t } from 'i18next';
import iziToast from 'izitoast';
import React from 'react';
import { Translation } from 'react-i18next';
import { Form, List, Message } from 'semantic-ui-react';

import type { History } from 'history';

import UsersApi from './api/UsersApi';
import { passwordTooltips, validatePassword } from './Components/Utilities/password';

import type { PasswordPolicy } from './types/User';

interface ActivationProps {
  activatePassword: (token: string | null, password: string) => void;
  history: History;
  activationSuccess: boolean;
}

export interface SetPasswordState {
  password: string;
  isLoading: boolean;
  token: string | null;
  UID: number | null;
  passwordPolicy?: PasswordPolicy;
  invalidToken: boolean;
}

export default class ActivationComponent extends React.Component<ActivationProps, SetPasswordState> {
  constructor(props: ActivationProps) {
    super(props);

    this.state = {
      password: '',
      token: null,
      isLoading: false,
      UID: null,
      invalidToken: false
    };
  }

  componentDidMount() {
    const [token, UID] = this.getToken();
    this.setState({ token: token, UID: UID });

    UsersApi.getPasswordPolicyNoAuth(token, UID)
      .then((policy) => {
        this.setState({ passwordPolicy: policy });
      })
      .catch(() => {
        this.setState({ invalidToken: true });
      });
  }

  componentDidUpdate() {
    if (this.props.activationSuccess) {
      iziToast.success({
        message: t('ACTIVATION_SUCCESSFUL'),
        position: 'bottomRight',
        icon: 'icon check'
      });
      this.props.history.push('/login');
    }
  }

  getToken = (): [string, number] => {
    const parameters = new URLSearchParams(window.location.search);
    const token = parameters.get('token') as string;
    const UID = Number(parameters.get('UID'));

    return [token, UID];
  };

  submitPassword = () => {
    if (this.state.passwordPolicy) {
      const passwordValidation = validatePassword(this.state.password, this.state.passwordPolicy);
      if (passwordValidation?.error) {
        iziToast.error({
          message: t(`settings.add_user_form.password.${passwordValidation.error}`, {
            amount: passwordValidation.required
          }),
          timeout: 7500
        });
        return;
      }
    }

    this.props.activatePassword(this.state.token, this.state.password);
  };

  render() {
    const canSubmit =
      this.state.password.length > 0 &&
      (!this.state.passwordPolicy || !validatePassword(this.state.password, this.state.passwordPolicy)?.error);

    return (
      <Translation ns="translations">
        {(t) => (
          <div className="loginWrapper">
            <div className="loginForm">
              <div className="logo" />

              {this.state.invalidToken && (
                <Message content={t('auth.tokenInvalid.message')} icon="warning sign" size="tiny" compact error />
              )}

              <Form style={{ width: '260px', paddingTop: '10px', margin: '10px 0' }}>
                <span className="redFont">
                  {this.state.passwordPolicy && <List>{passwordTooltips(this.state.passwordPolicy)}</List>}
                </span>
                <Form.Input
                  style={{ marginTop: '20px' }}
                  disabled={this.state.isLoading || this.state.invalidToken}
                  name="password"
                  type="password"
                  icon="lock"
                  placeholder={t('PLACEHOLDER_SET_PASSWORD')}
                  value={this.state.password}
                  onChange={(e, x) => this.setState({ password: x.value })}
                />
                <Form.Button style={{ marginTop: '20px' }} onClick={this.submitPassword} disabled={!canSubmit}>
                  {t('GENERAL_SEND')}
                </Form.Button>
              </Form>
            </div>
          </div>
        )}
      </Translation>
    );
  }
}
