import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { orderBy, partition } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import type { TicketType } from '@eeedo/types';

import Dropdown from '../generic/Dropdown/Dropdown';
import TagPopup from '../generic/TagPopup';
import { addEntityTags, deleteEntityTags } from 'src/actions/entityTagsActions';
import { useAppThunkDispatch } from 'src/store';
import { filterNonDefaultTags } from 'src/types/Tag';

import type { State } from 'src/types/initialState';

interface EntityTagsProps {
  tagIds: number[];
  ticketType: TicketType | undefined;
  entityId: string;
  entityType: string;
}

const EntityTags = ({ tagIds, ticketType, entityId, entityType }: EntityTagsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppThunkDispatch();
  const userData = useSelector(({ userData }: State) => userData);
  const tags = useSelector((state: State) => state.tags);

  const tagsSorted = orderBy(filterNonDefaultTags(tags), 'name');
  const [selectedTags, unselectedTags] = partition(tagsSorted, (tag) =>
    tagIds?.includes(parseInt(tag.id.substring(3), 10))
  );
  const tagOptions = unselectedTags
    .filter((tag) => !tag.deprecated)
    .filter((tag) => !tag.ticketTypes.length || !ticketType?.id || tag.ticketTypes?.includes(ticketType?.id))
    .map((tag) => ({ text: tag.name, value: parseInt(tag.id.substring(3), 10) }));

  return (
    <div style={{ margin: '1rem 0 0 0' }}>
      <Dropdown
        button
        search
        floating
        icon={<FontAwesomeIcon icon={faPlus} style={{ marginLeft: '5px' }} />}
        options={tagOptions}
        selectOnBlur={false}
        selectOnNavigation={false}
        text={t('entity_tags.labels.attach_tag')}
        disabled={!userData.permissions.includes('updateContent')}
        onChange={(_, data) => dispatch(addEntityTags({ entityId, entityType, tagIds: [data.value as number] }))}
        style={{ margin: '0 0 1rem 0' }}
      />

      <div className="eeedoTagGroup">
        {selectedTags.map((tag) => (
          <TagPopup
            key={tag.id}
            tag={tag}
            onClose={() => dispatch(deleteEntityTags({ entityId, tagIds: [parseInt(tag.id.substring(3), 10)] }))}
          />
        ))}
      </div>
    </div>
  );
};

export default EntityTags;
