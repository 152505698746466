import { Channels } from '@eeedo/types';
import { withTranslation } from 'react-i18next';

import { ReplySalesforce } from './ReplySalesforce';

class ReplyD365 extends ReplySalesforce {
  getDraftChannel(): Channels {
    return Channels.d365;
  }
}

export default withTranslation()(ReplyD365);
