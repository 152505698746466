import { Channels } from '@eeedo/types';
import iziToast from 'izitoast';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';

import type { PersonalData, ResponseTemplate, TicketType, UserWithProfile } from '@eeedo/types';
import type { WithTranslation } from 'react-i18next';

import ChannelType from '../CommentIconContent/ChannelType';
import FormDropzoneDropdown from './components/FormDropzoneDropdown';
import ReplyControlButtons from './components/ReplyControlButtons';
import ReplyVCDrafts from './components/ReplyVCDrafts';
import { KnowledgeBank } from './KnowledgeBank';
import { ReplyMethod } from './ReplyMethod';
import ReplyTemplates from './ReplyTemplates';
import ReplyTextArea from './ReplyTextArea';
import FeatureFlags from 'src/api/FeatureFlags';
import { Roles } from 'src/types/User';
import { insertAtCursor } from 'src/Utilities/insertAtCursor';
import { normalizePhoneNumber } from 'src/Utilities/normalizeNumber';

import type { ReplyMethodProps } from './ReplyMethod';
import type { Attachment, Entity, Ticket, UploadFileResult } from 'src/types/Ticket';

interface ReplyWhatsappProps extends ReplyMethodProps<ReplyWhatsappState>, WithTranslation {
  userData: PersonalData;
  ticketType: TicketType;
  templates: Array<ResponseTemplate>;
  entities: Entity[];
  drafts?: Partial<ReplyWhatsappState>;
  task: Ticket;
  users: UserWithProfile[];
  attachments: Array<Attachment>;
  __testContent?: string;

  // TODO: typing
  switchChatAnchor: (...args: any[]) => any;
  onSubmit: (...args: any[]) => any;
  uploadFile: (ticketId: string, file: FormData) => Promise<UploadFileResult[]>;
}

type ReplyWhatsappState = {
  content: string;
  isLoading: boolean;
  selectedAttachmentIds: string[];
  selectedReplyTemplate: string | undefined;
};

class ReplyWhatsapp extends ReplyMethod<ReplyWhatsappProps, ReplyWhatsappState> {
  private channel = ChannelType.WhatsApp;
  private editor: HTMLTextAreaElement | null;

  constructor(props: ReplyWhatsappProps) {
    super(props);
    this.state = this.getInitialState(this.props.drafts);
  }

  getDraftChannel(): Channels {
    return Channels.whatsapp;
  }

  getDraftState(state: ReplyWhatsappState): Partial<ReplyWhatsappState> {
    return {
      content: state.content,
      selectedAttachmentIds: state.selectedAttachmentIds,
      selectedReplyTemplate: state.selectedReplyTemplate
    };
  }

  /**
   * Return sender's whatsappId (phone number)
   */
  private getWaId = (): string | null => {
    const numbers: string[] = this.props.task.entities.filter((ent) => !!ent.data?.phone).map((ent) => ent.data.phone);
    if (numbers.length > 0) {
      return normalizePhoneNumber(numbers[0]);
    }
    return null;
  };

  /**
   * Return outgoint whatsappId (phone number)
   * It's extracted from comment metadata where `recipient` field should contain
   * that number
   */
  private getOutgoingId = (sender: string): string | null => {
    const numbers = this.props.task.comments
      .filter(
        (cmt) =>
          cmt.direction === 'in' &&
          cmt.metaData &&
          typeof cmt.metaData.phone === 'string' &&
          normalizePhoneNumber(cmt.metaData.phone) === sender
      )
      .map((cmt) => normalizePhoneNumber(cmt.metaData?.recipient as string));
    if (numbers.length > 0) {
      return numbers[0];
    }
    return null;
  };

  private getSelectedAttachments = (): Attachment[] => {
    return this.props.attachments.filter((att) => this.state.selectedAttachmentIds.includes(att.id));
  };

  private getInitialState = (drafts?: Partial<ReplyWhatsappState>) => {
    const state: ReplyWhatsappState = {
      isLoading: false,
      content: drafts?.content || this.props.__testContent || '',
      selectedAttachmentIds: drafts?.selectedAttachmentIds || [],
      selectedReplyTemplate: drafts?.selectedReplyTemplate || undefined
    };
    return state;
  };

  public submitComment = async () => {
    const { t } = this.props;
    if (this.state.isLoading || !this.state.content.length) {
      return;
    }

    const waId = this.getWaId();
    if (!waId || this.state.isLoading || !this.state.content.length) {
      return;
    }
    const sender = this.getOutgoingId(waId);
    if (!sender) {
      return;
    }
    this.setState({ isLoading: true });
    const bodyOfRequest = {
      content: this.state.content,
      channel: this.channel,
      direction: 'out',
      sendAsWhatsappMessage: true,
      to: [waId],
      sender: sender,
      attachments: this.getSelectedAttachments()
    };

    try {
      const response = await this.props.onSubmit(bodyOfRequest, false);

      if (response !== false) {
        this.clearFields();
      }
    } catch (error: any) {
      const errorResponse = error?.response;
      const errorMessages = {
        400: t('whatsappErrorMessages.400'),
        429: t('whatsappErrorMessages.429'),
        470: t('whatsappErrorMessages.470'),
        default: t('whatsappErrorMessages.default')
      };

      if (errorResponse?.status) {
        iziToast.error({
          title: `${t('ERROR')}!`,
          icon: 'icon delete',
          message: errorMessages[errorResponse.status] || errorMessages.default,
          timeout: 7500
        });
      }
    } finally {
      this.setState({ isLoading: false });
    }
  };

  private clearFields = () => {
    this.setState(
      {
        content: '',
        selectedAttachmentIds: [],
        selectedReplyTemplate: undefined
      },
      () => {
        this.saveDraft(this.state);
      }
    );
  };

  componentWillReceiveProps(nextProps: ReplyWhatsappProps) {
    if (this.props.taskId !== nextProps.taskId) {
      this.setState(this.getInitialState(nextProps.drafts));
    }

    if (this.props.drafts?.content !== nextProps.drafts?.content) {
      this.setState({ content: nextProps.drafts?.content || '' });
    }
  }

  private onUploadAttachment = async (attachmentFiles: File[]) => {
    const temp: string[] = this.state.selectedAttachmentIds;

    return Promise.all(
      attachmentFiles.map((file) => {
        const data = new FormData();
        data.append('attachments', file);
        return this.props.uploadFile(this.props.taskId, data).then((files) => {
          const attachmentIdArray = files.map((att) => {
            return att.attachmentId;
          });

          const allIds = [...temp, ...attachmentIdArray];

          this.updateState({ selectedAttachmentIds: allIds });
          return files;
        });
      })
    );
  };

  render() {
    const { t } = this.props;
    const attachments = this.getAttachmentsOptions();

    return (
      <Form reply={true} style={{ marginTop: '20px' }}>
        <Form.Field>
          <label>{t('ADD_COMMENT_CANNED_RESPONSE')}</label>
          <ReplyTemplates
            channel={this.channel}
            entities={this.props.entities}
            userData={this.props.userData}
            ticketType={this.props.ticketType}
            templates={this.props.templates}
            task={this.props.task}
            selectedOption={this.state.selectedReplyTemplate}
            setSelectedOption={(value) => this.setState({ selectedReplyTemplate: value })}
            setContent={(value) => this.updateState({ ...value })}
            insertAtCursor={(value, content) => {
              value.content = insertAtCursor(this.editor, content);
              this.updateState({ ...value });
            }}
            content={this.state.content}
            users={this.props.users}
            discardHtml={true}
          />
        </Form.Field>
        {FeatureFlags.isFlagOn('ENABLE_EGAIN') && (
          <Form.Field>
            <KnowledgeBank
              title={this.state.content}
              id={this.props.taskId}
              extraArguments={this.props.ticketType.knowledgeBank}
              content={this.state.content}
              updateState={(value: string) => {
                this.updateState({ content: value });
              }}
            />
          </Form.Field>
        )}
        <ReplyVCDrafts taskId={this.props.taskId} channelId={this.channel} onClickLoad={this.loadVCDraft} />
        <Form.Field id="commentContentField">
          <label>{t('ADD_COMMENT_CONTENT')}</label>
          <ReplyTextArea
            ref={(ref) => (this.editor = ref)}
            content={this.state.content}
            onChange={(content) => {
              this.updateState({ content });
            }}
            onKeyDown={this.handleHotKeys}
          />
        </Form.Field>

        <FormDropzoneDropdown
          attachments={this.props.attachments}
          onChangeAttachments={(addedAttachments) => this.updateState({ selectedAttachmentIds: addedAttachments })}
          onDropAccepted={this.onUploadAttachment}
          options={attachments}
          value={this.state.selectedAttachmentIds}
        />

        <Form.Group inline>
          <Form.Checkbox
            toggle={true}
            onClick={() => {
              this.props.switchChatAnchor(!Roles.isChatAnchored(this.props.userData));
            }}
            checked={Roles.isChatAnchored(this.props.userData)}
            label={t('ANCHOR_CHAT')}
          />
        </Form.Group>
        <ReplyControlButtons
          small={this.props.smallButtons}
          disabled={this.state.isLoading || !this.state.content.length}
          loading={this.state.isLoading}
          onClear={this.clearFields}
          onSaveDraft={() => this.saveVCDraft(false)}
          onSubmit={this.submitComment}
        />
      </Form>
    );
  }
}

export default withTranslation('translations')(ReplyWhatsapp);
