import type { EmailSendingStatus, TicketDirection } from '@eeedo/types';

import type { ContentTypes } from './ContentTypes';

export type CommentTypes = 'normal' | 'automatic' | 'suggestion' | 'survey' | 'emailsent' | 'emailreceived' | 'helper';
export type StatusTypes = 'todo' | 'doing' | 'done' | 'waste';

// do we still need this type when we have CaseType?
export type ContentTypesFields = 'tickets' | 'infopages';
export type InfopageStatusTypes = 'draft' | 'inReview' | 'waitingToBePublished' | 'published' | 'waste';

export enum CommentMetadataType {
  ticketType = 'ticketType',
  merge = 'merge',
  ticketPriority = 'ticketPriority',
  unansweredPhoneCall = 'unansweredPhoneCall',
  openExternalLink = 'openExternalLink'
}

export interface CommentMetadata {
  buttons?: [
    {
      label: string;
      buttonState: string;
      type: string;
      target?: string;
      url?: string;
    }
  ];
  userClickedButton?: string;
  phone?: string;
  recipient?: string;
  responseTo?: string;
  type?: CommentMetadataType;
  previousValue?: unknown;
  value?: unknown;
  title?: string;
  from?: string | string[];
  to?: string[];
  cc?: string[];
  attachments?: any[];
  html?: string;
  mainContentId?: number;
  mergedContents?: number[];
  // WhatsUp specific usecase
  name?: string;
  parentCommentId?: number;
  bot?: { name: string };
  duration?: string;
  acdName?: string;
  sender?: string;
  email?: string; // Webform
  msgid?: string;
  sendingStatus?: EmailSendingStatus;
}

export interface Comment {
  channel: number | null;
  content: string | null;
  parsedContent?: string | null;
  truncatedContent?: string | null;
  textOnly: string | null;
  created: number;
  createdByUser: string;
  direction: TicketDirection | null;
  dislikedUsers?: string[];
  dislikes: number;
  id: string;
  likedUsers?: string[];
  likes: number;
  mentionedUsers?: string[];
  metaData: CommentMetadata | null;
  title: string | null;
  type: CommentTypes;
  foreignId: string | null;
  foreignIdType: string | null;
}

export interface CommentFilters {
  direction?: TicketDirection;
  channels?: number[];
  createdAfter?: number;
  createdBefore?: number;
  text?: string;
  author?: string[];
  hasAttachment?: boolean;
}

export interface TicketListTicket {
  id: string;
  type?: ContentTypes;
  channel: number;
  UID: number;
  createdByUser?: string;
  created: number;
  touched: number;
  taskType: string;
  lastEdited: number | null;
  title: string;
  dueDate: number | null;
  handledByUser?: number | null;
  tags: string[] | null;
  delegates?: string[];
  delegatedTo?: string[];
  priority: number;
  entities?: Entity[];
  originalContact?: string | null;
  originalDirection: TicketDirection;
  lastContactAddress?: string | null;
  status?: StatusTypes | InfopageStatusTypes;
  comments?: Comment[];
  entityIds?: string[];
}

export interface Ticket extends TicketListTicket {
  id: string;

  /**
   * @deprecated USE isChatAnchored TO READ THIS PROPERTY.
   * This would be protected inside a class
   * if it didn't break half the codebase.
   */
  chatAnchored?: boolean;
  firstResponseTime?: number;
  originalContactChannel?: number;
  type: ContentTypes;
  createdByUser: string;
  created: number;
  touched: number;
  editedByUser: string;
  title: string;
  content: string;
  dueDate: number;
  status: StatusTypes;
  metaData: object | null;
  likes: number;
  dislikes: number;
  deprecated: boolean;
  channel: number;
  taskType: string;
  delegatedTo: string[];
  tags: string[];
  attachments: Attachment[];
  comments: Comment[];
  commentsIsLoading?: boolean;
  entities: Entity[];
  entityFields?: any;
  entityFieldDisplayName?: string;
  priority: number;
  originalDirection: TicketDirection;
  case: any;
  weight?: number; // float
}

export interface TicketComments {
  id: string;
  comments: Comment[];
}

export interface EntityData extends Record<string, any> {
  _id?: string;
  email?: string;
  taskType?: string;
  lastName?: string;
  phone?: string;
  firstName?: string;
  mongoCustomer?: boolean;
  _type?: string;
}

export interface Entity {
  _id: string;
  _type: string;
  mongoCustomer?: boolean;
  data?: any;

  [x: string]: any;
}

export interface DBEntity {
  contentId: number;
  entityId: string;
  entityType: string;
}

export type EntityResponse = Entity & {
  ticketId: string;
  isLoading?: boolean;
  hasAccess: boolean;
  statusCode: number;
  errorMessage?: string;
};

export interface SubEntityResponse {
  _id: string;
  _type: string;
  parentEntityType: string;
  data?: Entity;
  ticketId: string;
  parentEntityId: string;
  isLoading?: boolean;
  hasAccess: boolean;
  statusCode: number;
  errorMessage?: string;
}

export interface Attachment {
  id: string;
  uri: string;
  previewUri?: string;
  uploaded: number;
  fileName: string;
  fileType: string;
  fileHash?: string;
  deprecated: boolean;
  description: string;
  cId: string | null;
  title: string | null;
  indexed: boolean;
  thumbnail: boolean;
  isQuarantined: boolean;
  isDuplicated: boolean;
  size: number | null;
}

export interface UploadFileResult {
  id: number;
  fieldname: string;
  originalname: string;
  encoding: string;
  mimetype: string;
  destination: string;
  filename: string;
  path: string;
  size: number;
  isQuarantined: boolean;
  secret: string;
  attachmentId: string;
  cId: string;
}

export interface Task {
  attachments: Array<Attachment>;
  comments: Comment[];
  entities: Entity[];
  customer: any;
  case: any;
  product: any;
  content: string;
  created: number;
  createdByUser: string;
  delegatedTo: string[];
  deprecated: boolean;
  dislikedUsers: string[];
  dislikes: number;
  dueDate: number;
  editedByUser: string;
  taskType: string;
  id: string;
  lastEdited: number;
  channel: number;
  likes: number;
  numOfComments: number;
  tags: string[];
  title: string;
  touched: number;
  active: boolean;
  delegate: any;
  entityFields: any;
  entityFieldDisplayName: string;
  priority: number;
}

export const ExampleTicket: Ticket = {
  attachments: [],
  case: {},
  channel: 1,
  chatAnchored: false,
  comments: [],
  content: '',
  created: 1,
  createdByUser: 'USR1',
  delegatedTo: ['USR1'],
  deprecated: false,
  lastEdited: 2,
  touched: 3,
  likes: 0,
  dislikes: 0,
  dueDate: 4,
  entities: [],
  editedByUser: 'USR1',
  id: 'TSK1',
  metaData: {},
  tags: ['TAG1'],
  taskType: 'default',
  title: '',
  type: 'task',
  status: 'todo',
  entityFields: [],
  priority: 0,
  originalDirection: 'in',
  UID: 0,
  delegates: []
};

export interface TicketDetailsData {
  comments: Comment[];
  entities: Entity[];
  editedByUser: string;
}
