import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import type { FC } from 'react';

import Label from '../generic/Label/Label';
import * as styles from './HideMessagesToggle.style';

interface HideMessagesToggleProps {
  text: string;
  isCollapsed: boolean;
  disableCollapsing: boolean;

  onClick(): void;
}

const HideMessagesToggle: FC<HideMessagesToggleProps> = ({ text, isCollapsed, disableCollapsing, onClick }) => {
  if (disableCollapsing) return null;

  const icon = isCollapsed ? faChevronUp : faChevronDown;

  return (
    <p onClick={onClick} style={styles.wrapper}>
      <Label style={styles.label}>
        <FontAwesomeIcon icon={icon} /> {text} <FontAwesomeIcon icon={icon} />
      </Label>
    </p>
  );
};

export default React.memo(HideMessagesToggle);
