import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

import type { ComponentProps, FC } from 'react';
import type { SemanticICONS } from 'semantic-ui-react';

import Button from './Button/Button';

interface ReplyControlButtonProps extends ComponentProps<typeof Button> {
  iconName?: SemanticICONS;
  shrink?: boolean;
}

const ShrinkableButton: FC<ReplyControlButtonProps> = ({ children, iconName, shrink, ...buttonProps }) => {
  const button = (
    <Button {...buttonProps} iconLeft={<Icon name={iconName} />}>
      {!shrink && children}
    </Button>
  );

  return shrink ? <Popup trigger={button} content={children} /> : button;
};

export default React.memo(ShrinkableButton);
