import { faArrowRightToArc } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';
import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Divider, Form, Icon } from 'semantic-ui-react';

import type { InputOnChangeData } from 'semantic-ui-react';

import ApiConfig from './api/ApiConfig';
import Button from './Components/generic/Button/Button';
import Input from './Components/generic/Input/Input';
import { login } from 'src/actions/authActions';
import { getURLParam } from 'src/Utilities/helper';

import type { State } from 'src/types/initialState';
import type { ThunkAppDispatch } from 'src/types/store';

export type LoginFunction = (user: string, pasword: string) => Promise<void>;

export interface LoginProps {
  logUserIn: LoginFunction;
  isUserAuthenticated: boolean;
  hasEnteredIncorrectLoginCredentials: boolean;
  initialAddress: string;
  activationSuccess: boolean;
}

export interface LoginState {
  user: string;
  password: string;
  isLoading: boolean;
}

class LoginComponent extends Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);

    this.state = {
      user: '',
      password: '',
      isLoading: false
    };
  }

  private buttonHandler = () => {
    if (!this.state.isLoading) {
      this.setState(
        {
          isLoading: true
        },
        async () => {
          await this.props.logUserIn(this.state.user, this.state.password);
          this.setState({ isLoading: false });
        }
      );
    }
  };

  private handleRedirect = () => {
    const redirectUrl = getURLParam<string | undefined>(window.location.search, 'redirectUrl');
    const searchParam = redirectUrl ? `?${queryString.stringify({ redirectUrl })}` : '';

    return (window.location = (ApiConfig.getConfig().API_URL + `/login/${searchParam}`) as unknown as Location);
  };

  private handleChange = (_: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    this.setState({ ...this.state, [data.name]: data.value });
  };

  render() {
    return (
      <Translation ns="translations">
        {(t) => (
          <div className="loginWrapper">
            {this.props.isUserAuthenticated ? (
              <Redirect to={localStorage.getItem('initialAddress') || '/'} />
            ) : (
              <>
                <div className="logo loginLogo" />
                <div className="loginForm">
                  <div className="loginHeader">
                    <h1>{t('LOGIN_TITLE')}</h1>
                    <h2>{t('LOGIN_SUBTITLE')}</h2>
                  </div>
                  <Form>
                    {ApiConfig.getConfig().LIKEIT_AUTH === true && (
                      <>
                        <Button
                          fullWidth
                          htmlType="button"
                          center
                          onClick={this.handleRedirect}
                          content={
                            <span>
                              <Icon name="sign in" />
                              Kirjaudu Likeit-tunnuksilla
                            </span>
                          }
                        />
                        <Divider horizontal>{t('OR')}</Divider>
                        <div className="loginFormManualLogin">
                          <Input
                            label={t('PLACEHOLDER_USERNAME')}
                            error={this.props.hasEnteredIncorrectLoginCredentials}
                            disabled={this.state.isLoading}
                            autoComplete="username"
                            name="user"
                            placeholder={t('PLACEHOLDER_USERNAME')}
                            onChange={this.handleChange}
                            value={this.state.user}
                          />

                          <div style={{ position: 'relative' }}>
                            <Input
                              label={t('PLACEHOLDER_PASSWORD')}
                              error={this.props.hasEnteredIncorrectLoginCredentials}
                              autoComplete="current-password"
                              disabled={this.state.isLoading}
                              name="password"
                              type="password"
                              placeholder={t('PLACEHOLDER_PASSWORD')}
                              onChange={this.handleChange}
                              value={this.state.password}
                            />

                            <div style={{ position: 'absolute', top: 0, right: 0 }}>
                              <Link className="forgotPasswordLink" to="/forgotPassword">
                                {t('LOGIN_FORGOTPASSWORD')}
                              </Link>
                            </div>
                          </div>

                          <Button
                            id="loginButton"
                            htmlType="submit"
                            disabled={this.state.isLoading}
                            onClick={this.buttonHandler}
                            content={t('LOGIN_LOG_IN')}
                            iconLeft={<Icon name="sign in" />}
                            type="primary"
                            style={{ marginTop: '12px' }}
                            size="lg"
                            center
                          />
                        </div>
                      </>
                    )}
                    {ApiConfig.getConfig().LIKEIT_AUTH === false && ApiConfig.getConfig().AD_ENABLED !== true && (
                      <div className="loginFormManualLogin">
                        <Input
                          label={t('PLACEHOLDER_USERNAME')}
                          error={this.props.hasEnteredIncorrectLoginCredentials}
                          disabled={this.state.isLoading}
                          name="user"
                          placeholder={t('PLACEHOLDER_USERNAME')}
                          onChange={this.handleChange}
                          value={this.state.user}
                        />

                        <div style={{ position: 'relative' }}>
                          <Input
                            label={t('PLACEHOLDER_PASSWORD')}
                            error={this.props.hasEnteredIncorrectLoginCredentials}
                            autoComplete="current-password"
                            disabled={this.state.isLoading}
                            name="password"
                            type="password"
                            placeholder={t('PLACEHOLDER_PASSWORD')}
                            onChange={this.handleChange}
                            value={this.state.password}
                          />

                          <div style={{ position: 'absolute', top: 0, right: 0 }}>
                            <Link className="forgotPasswordLink" to="/forgotPassword">
                              {t('LOGIN_FORGOTPASSWORD')}
                            </Link>
                          </div>
                        </div>

                        <Button
                          icon={true}
                          htmlType="submit"
                          iconLeft={<Icon name="sign in" />}
                          onClick={this.buttonHandler}
                          content={t('LOGIN_LOG_IN')}
                          type="primary"
                          size="lg"
                          style={{ marginTop: '12px' }}
                          center
                        ></Button>
                      </div>
                    )}
                    {ApiConfig.getConfig().AD_ENABLED === true && (
                      <>
                        <Button
                          fullWidth
                          center
                          htmlType="button"
                          onClick={this.handleRedirect}
                          content={
                            <span>
                              <Icon name="microsoft" />
                              {t('LOGIN_WITH_MICROSOFT_CREDENTIALS')}
                            </span>
                          }
                        />
                        <Divider horizontal>{t('OR')}</Divider>
                        {
                          <div className="loginFormManualLogin">
                            <Input
                              label={t('PLACEHOLDER_USERNAME')}
                              error={this.props.hasEnteredIncorrectLoginCredentials}
                              disabled={this.state.isLoading}
                              name="user"
                              placeholder={t('PLACEHOLDER_USERNAME')}
                              onChange={this.handleChange}
                              value={this.state.user}
                            />

                            <div style={{ position: 'relative' }}>
                              <Input
                                label={t('PLACEHOLDER_PASSWORD')}
                                error={this.props.hasEnteredIncorrectLoginCredentials}
                                autoComplete="current-password"
                                disabled={this.state.isLoading}
                                name="password"
                                type="password"
                                placeholder={t('PLACEHOLDER_PASSWORD')}
                                onChange={this.handleChange}
                                value={this.state.password}
                              />

                              <div style={{ position: 'absolute', top: 0, right: 0 }}>
                                <Link className="forgotPasswordLink" to="/forgotPassword">
                                  {t('LOGIN_FORGOTPASSWORD')}
                                </Link>
                              </div>
                            </div>

                            <Button
                              id="loginButton"
                              htmlType="submit"
                              disabled={this.state.isLoading}
                              onClick={this.buttonHandler}
                              content={t('LOGIN_LOG_IN')}
                              iconLeft={<FontAwesomeIcon icon={faArrowRightToArc} />}
                              type="primary"
                              size="lg"
                              style={{ marginTop: '12px' }}
                              center
                            />
                          </div>
                        }
                      </>
                    )}
                  </Form>
                </div>
              </>
            )}
          </div>
        )}
      </Translation>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    isUserAuthenticated: state.auth.loggedIn,
    hasEnteredIncorrectLoginCredentials: state.auth.hasEnteredIncorrectLoginCredentials,
    initialAddress: state.auth.initialAddress,
    activationSuccess: state.auth.activationSuccess
  };
};

const mapDispatchToProps = (dispatch: ThunkAppDispatch) => {
  return {
    logUserIn: (user: string, password: string) => dispatch(login(user, password))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
