import { filter } from 'lodash-es';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import type { Field, FieldSet } from '@eeedo/types';
import type { ConnectedProps } from 'react-redux';

import AccordionHeader from '../AccordionHeader';
import Attachments from '../Attachments';
import ExternalLinksList from '../ExternalLinksList';
import { MetadataEditor } from '../Metadata/MetadataEditor';
import AttachEntityById from './AttachEntityById';
import CaseChannel from './CaseChannel';
import ChatGPTWidget from './ChatGPT/ChatGPTWidget';
import CustomerInfo from './CustomerInfo';
import Delegations from './Delegations';
import EIdentificationWidget from './EIdentification/EIdentificationWidget';
import FieldSets from './FieldSets';
import HandleStatus from './HandleStatus';
import PriorityWidget from './PriorityWidget';
import RescheduleCallback from './RescheduledCallback/RescheduleCallback';
import SurveyContainer from './Survey/SurveyContainer';
import TicketRelations from './TicketRelationsWidget/TicketRelations';
import TranscriptionsWidget from './TranscriptionsWidget';
import { useWidgetView } from './WidgetViewContext';
import {
  changeEntity,
  deprecateAttachment,
  editAttachment,
  fireTicketSearch,
  unDeprecateAttachment,
  updateMetadata,
  updateSingleTicketCaseDetail,
  updateTicket,
  uploadFile
} from 'src/actions/ticketsActions';
import FeatureFlags from 'src/api/FeatureFlags';
import SuggestedArticles from 'src/Components/Case/InfopageCase/Widget/SuggestedArticles/SuggestedArticles';
import TagsWidget from 'src/Components/Case/Widget/TagsWidget';
import { getWidgetOrder } from 'src/Components/Utilities/widgets';
import { selectActiveTicket, selectCurrentUser } from 'src/selectors/rootStateSelectors';
import { taskIdToNumericalId } from 'src/Utilities/ticketList';

import type { AttachmentEdit } from '../../Attachments/AttachmentItem';
import type { AssetsMetaWidget } from './Assets/AssetsMeta';
import type { State } from 'src/types/initialState';
import type { RelationOptions } from 'src/types/LinkedTickets';
import type { SearchCriterion } from 'src/types/Search';
import type { ThunkAppDispatch } from 'src/types/store';
import type { Ticket } from 'src/types/Ticket';

const AssetsMeta = lazy(() => import('./Assets/AssetsMeta'));
const AssetsUsage = lazy(() => import('./Assets/AssetsUsage'));

type WidgetsProps = WidgetsReduxProps;

interface WidgetDataState {
  detailGroupMap: Map<string, string>;
  entityInfo: Field[];
  entityInfoHeader: string;
  entitySearchButton: string;
  fieldMap: Map<string, Field>;
  fieldSetNames: string[];
  handleInfo: Field[];
  fieldSetsMap: Map<string, FieldSet[]>;
}

const Widgets = ({
  task,
  ticketTypes,
  user,
  userData,
  externalLinks,
  updateMetadata,
  changeEntity,
  fireTicketSearch,
  uploadFile,
  editAttachment,
  deprecateAttachment,
  unDeprecateAttachment,
  updateTicket
}: WidgetsProps) => {
  const { t } = useTranslation();
  const { isSingleWidgetView, selectedWidget } = useWidgetView();
  const [widgetData, setWidgetData] = useState({
    detailGroupMap: new Map(),
    entityInfo: [],
    entityInfoHeader: t('CASE_TITLE_CUSTOMER'),
    entitySearchButton: '',
    fieldMap: new Map(),
    fieldSetNames: [],
    handleInfo: [],
    fieldSetsMap: new Map()
  } as WidgetDataState);

  useEffect(() => {
    if (!task) {
      return;
    }

    const widgetDataPayload: WidgetDataState = {
      ...widgetData,
      entityInfo: [],
      handleInfo: [],
      fieldSetsMap: new Map()
    };

    if (task.entityFields) {
      widgetDataPayload.entityInfo = task.entityFields;
      widgetDataPayload.entityInfoHeader = task.entityFieldDisplayName ?? '';
    }

    if (ticketTypes?.length > 0 && !task.entityFields) {
      ticketTypes.forEach((ticketType) => {
        widgetDataPayload.fieldSetsMap.set(ticketType.name, ticketType.fieldSets);
      });

      widgetDataPayload.detailGroupMap = new Map();
      widgetDataPayload.fieldMap = new Map();
      widgetDataPayload.fieldSetNames = [];

      const ticketType = ticketTypes.find((type) => type.name === task.taskType)!;
      const fieldSets = widgetDataPayload.fieldSetsMap.get(task.taskType);
      if (fieldSets?.length) {
        fieldSets.forEach((fieldSet) => {
          if (fieldSet.id === 'caseInfo') {
            widgetDataPayload.entitySearchButton = fieldSet.customSearch!;
          }

          if (fieldSet.id === 'customerInfo') {
            widgetDataPayload.entityInfo = fieldSet[fieldSet.id]!;
            widgetDataPayload.entityInfoHeader = ticketType.defaultFieldSet
              ? ticketType.defaultFieldSet
              : fieldSet.displayName;
          } else if (fieldSet.id === 'status') {
            widgetDataPayload.handleInfo = fieldSet[fieldSet.id];
          } else {
            widgetDataPayload.fieldSetNames.push(fieldSet.displayName);
            widgetDataPayload.detailGroupMap.set(fieldSet.displayName, fieldSet.group);
            widgetDataPayload.fieldMap.set(fieldSet.displayName, fieldSet[fieldSet.id]);
          }
        });
      }
    }

    setWidgetData(widgetDataPayload);
  }, [task?.entityFields, task?.taskType, ticketTypes]);

  const relationOptions: RelationOptions = [
    {
      text: t('TICKET_RELATIONS_CHOICE_PARENT_TO_CHILD'),
      value: 'parentToChild'
    },
    {
      text: t('TICKET_RELATIONS_CHOICE_CHILD_TO_PARENT'),
      value: 'childToParent'
    }
  ];

  const handleChannelChange = (channel: number) => {
    if (task) {
      updateTicket(task.id, {
        channel
      });
    }
  };

  const handlePriorityChange = (priority: number) => {
    if (task) {
      updateTicket(task.id, {
        priority
      });
    }
  };

  const fireCaseInfoSearch = (fields: Field[], values: any[]) => {
    const searchIncludedObjs = fields.map((f) => ({ ...f, includeToSearch: true }));
    const searchArray: SearchCriterion[] = searchIncludedObjs.map((x) => ({
      value: values[x.value],
      datagroup: 'detail',
      name: x.value,
      object: false,
      param: x.value,
      text: ''
    }));

    fireTicketSearch(searchArray);
  };

  const changeEntityFields = (entityName: string, id: string) => {
    const tType = ticketTypes.find(
      (type) => type.name === task.taskType && type.fieldSets.some((field) => field.displayName === entityName)
    );
    const data = filter(tType!.fieldSets, (field) => field.displayName === entityName);

    changeEntity(data[0][id], task.id, entityName);
  };

  if (!task) {
    return null;
  }

  const attachments = task?.attachments.filter((attachment) => !attachment.isDuplicated);

  const { detailGroupMap, entityInfo, entityInfoHeader, entitySearchButton, fieldMap, fieldSetNames, handleInfo } =
    widgetData;

  return getWidgetOrder(task, ticketTypes, selectedWidget, isSingleWidgetView).map((widget, index) => {
    const widgetOpen = !widget.defaultClosed;
    const widgetName = widget.name;
    const widgetId = `widget_${widgetName}`;

    return (
      <React.Fragment key={widget.name + index}>
        {widgetName === 'RescheduleCallback' && (
          <AccordionHeader
            as="h4"
            active={widgetOpen}
            title={t('widgets.reschedule_call.scheduleMessage')}
            icon="calendar"
            key={'case-ticket-relation-accordion-header-1'}
            isWidget={true}
            id={widgetId}
          >
            <RescheduleCallback contentId={taskIdToNumericalId(task.id)} />
          </AccordionHeader>
        )}

        {widgetName === 'SuggestedArticles' && FeatureFlags.isFlagOn('ENABLE_KNOWLEDGE_BASE') && (
          <SuggestedArticles id={widgetId} key={`suggested-articles-widget-${index}`} />
        )}

        {widgetName === 'Metadata' && (
          <MetadataEditor
            id={widgetId}
            key={`case-metadata-editor-${index}`}
            disabled={false}
            metadata={task.metaData}
            onSave={(data: object) => {
              updateMetadata(task.id as any, data);
            }}
          />
        )}

        {widgetName === 'Priority' && (
          <PriorityWidget
            id={widgetId}
            task={task}
            widgetOpen={widgetOpen}
            handlePriorityChange={handlePriorityChange}
            ind={index}
          />
        )}

        {widgetName === 'CaseChannel' && (
          <CaseChannel
            id={widgetId}
            ind={index}
            task={task}
            widget={widget}
            widgetOpen={widgetOpen}
            handleChannelChange={handleChannelChange}
          />
        )}

        {widgetName === 'HandleStatus' && handleInfo.length !== 0 && (
          <HandleStatus id={widgetId} task={task} widgetOpen={widgetOpen} ind={index} handleInfo={handleInfo} />
        )}

        {widgetName === 'AttachEntityById' && (
          <AttachEntityById
            id={widgetId}
            task={task}
            widgetOpen={widgetOpen}
            ind={index}
            entityInfoHeader={entityInfoHeader}
            entityInfo={entityInfo}
            changeEntity={changeEntityFields}
          />
        )}

        {widgetName === 'CustomerInfo' && (
          <div id={widgetId}>
            <CustomerInfo
              widgetOpen={widgetOpen}
              itemsClosed={widget.itemsClosed}
              fireSearch={(value: any, type: string) => {
                const searchFormat: SearchCriterion[] = [
                  {
                    value: value,
                    datagroup: 'entity',
                    name: type,
                    object: false,
                    param: type,
                    text: ''
                  }
                ];
                if (FeatureFlags.isFlagOn('LIMIT_CLICKTOSEARCH_ENTITYTYPES')) {
                  searchFormat.push({
                    param: 'ticketTypesOr',
                    name: 'ticketTypesOr',
                    value: task.taskType,
                    datagroup: 'basic'
                  });
                }

                fireTicketSearch(searchFormat);
              }}
            />
          </div>
        )}

        {widgetName === 'FieldSets' && (
          <FieldSets
            id={widgetId}
            task={task}
            fireTicketSearch={fireTicketSearch}
            widgetOpen={widgetOpen}
            fieldSetNames={fieldSetNames}
            ind={index}
            entitySearchButton={entitySearchButton}
            fieldMap={fieldMap}
            detailGroupMap={detailGroupMap}
            fireCaseInfoSearch={fireCaseInfoSearch}
          />
        )}

        {widgetName === 'Delegations' && (
          <Delegations id={widgetId} task={task} widgetOpen={widgetOpen} widget={widget} />
        )}

        {widgetName === 'TicketRelations' && (
          <div className={'TicketRelationsContainer'}>
            <AccordionHeader
              id={widgetId}
              as="h4"
              active={widgetOpen}
              title={t('CASE_TITLE_TICKETRELATIONS')}
              icon="info circle"
              key={'case-ticket-relation-accordion-header-1'}
              isWidget={true}
            >
              <TicketRelations
                type={'task'}
                task={task}
                relationOptions={relationOptions}
                createText={t('CASE_CREATE_RELATED_TICKET')}
                attachPlaceholderText={t('CASE_ADD_TICKET_RELATION_BY_ID')}
                parentsText={t('CASE_TICKETRELATIONS_PARENTS')}
                childrenText={t('CASE_TICKETRELATIONS_CHILDREN')}
                sameOriginText={t('CASE_TICKETRELATIONS_SAME_ORIGIN')}
              />
            </AccordionHeader>
          </div>
        )}

        {widgetName === 'Tags' && (
          <TagsWidget
            id={widgetId}
            displayName={widget.displayName || t('CASE_TITLE_TAGS')}
            widget={widget}
            widgetOpen={widgetOpen}
          />
        )}

        {widgetName === 'ExternalLinks' &&
          externalLinks &&
          externalLinks
            .filter(
              (linklist) =>
                linklist.ticketTypes?.some((type) => type === task.taskType) || !linklist.ticketTypes?.length
            )
            .map((linklist) => (
              <AccordionHeader
                id={widgetId}
                as="h4"
                active={widgetOpen}
                title={linklist.listname}
                icon="info circle"
                key={`case-external-link-accordion-header-${linklist._id}`}
                isWidget={true}
              >
                <ExternalLinksList links={linklist.links} task={task} />
              </AccordionHeader>
            ))}

        {widgetName === 'Attachments' && (
          <>
            <AccordionHeader
              id={widgetId}
              as="h4"
              active={widgetOpen}
              title={`${t('CASE_TITLE_ATTACHMENTS')}`}
              label={filter(attachments, (att) => !att['deprecated']).length}
              icon="attach"
              isWidget={true}
            >
              <Attachments
                user={user!}
                onUpload={(files: File[]) => {
                  files.forEach((file) => {
                    const data = new FormData();
                    data.append('attachments', file);
                    uploadFile(task.id, data);
                  });
                }}
                dropZoneEnabled={userData.permissions.includes('updateContent')}
                onEdit={(attachmentId: string, body: AttachmentEdit) => {
                  editAttachment(task.id, attachmentId, body);
                }}
                onDeprecate={(attachmentId: string) => {
                  deprecateAttachment(task.id, attachmentId);
                }}
                onUnDeprecate={(attachmentId: string) => {
                  unDeprecateAttachment(task.id, attachmentId);
                }}
                attachments={attachments}
              />
            </AccordionHeader>
          </>
        )}

        {widgetName === 'EIdentification' && FeatureFlags.isFlagOn('ENABLE_EIDENTIFICATION') === true && (
          <EIdentificationWidget id={widgetId} task={task} contentDetails={task.case} />
        )}

        {widgetName === 'Transcriptions' && (
          <TranscriptionsWidget id={widgetId} widgetOpen={widgetOpen} contentId={task.id} />
        )}

        {widgetName === 'ChatGPT' && FeatureFlags.isFlagOn('ENABLE_OPENAI') === true && (
          <ChatGPTWidget id={widgetId} task={task} />
        )}

        {widgetName === 'Surveys' && FeatureFlags.isFlagOn('ENABLE_SURVEY_TICKET_INFO') === true && (
          <AccordionHeader
            id={widgetId}
            as="h4"
            active={widgetOpen}
            title={`${t('CASE_SURVEYS')}`}
            icon="talk"
            isWidget={true}
          >
            <SurveyContainer taskId={task.id} />
          </AccordionHeader>
        )}

        {widgetName === 'AssetsMeta' && (
          <Suspense fallback={null}>
            <AssetsMeta id={widgetId} task={task} widget={widget as AssetsMetaWidget} />
          </Suspense>
        )}

        {widgetName === 'AssetsUsage' && (
          <Suspense fallback={null}>
            <AssetsUsage id={widgetId} task={task} widgetOpen={widgetOpen} />
          </Suspense>
        )}
      </React.Fragment>
    );
  });
};

const connector = connect(
  (state: State) => ({
    task: selectActiveTicket(state)!,
    user: selectCurrentUser(state),
    userData: state.userData,
    externalLinks: state.linkLists,
    ticketTypes: state.ticketTypes
  }),
  (dispatch: ThunkAppDispatch) => ({
    updateMetadata: (id: number, metadata: { [key: string]: any }) => {
      dispatch(updateMetadata(id, metadata));
    },
    updateTicketCaseDetails: (ticketId: string, updateKey: string, updateValue: any, group: string) => {
      dispatch(updateSingleTicketCaseDetail(ticketId, updateKey, updateValue, group));
    },
    uploadFile: (ticketId: string, file: FormData) => {
      dispatch(uploadFile(ticketId, file));
    },
    editAttachment: (ticketId: string, attachmentId: string, body: AttachmentEdit) => {
      dispatch(editAttachment(ticketId, attachmentId, body));
    },
    deprecateAttachment: (ticketId: string, attachmentId: string) => {
      dispatch(deprecateAttachment(ticketId, attachmentId));
    },
    unDeprecateAttachment: (ticketId: string, attachmentId: string) => {
      dispatch(unDeprecateAttachment(ticketId, attachmentId));
    },
    updateTicket: (id: string, ticket: Partial<Ticket>, closeAfterUpdate?: boolean) => {
      dispatch(updateTicket({ id, ticket, closeAfterUpdate }));
    },
    fireTicketSearch: (searchCriteria: SearchCriterion[]) => {
      dispatch(fireTicketSearch({ searchCriteria }));
    },
    changeEntity: (data: any, id: any, entityDisplayName: string) => {
      dispatch(changeEntity(data, id, entityDisplayName));
    }
  })
);

type WidgetsReduxProps = ConnectedProps<typeof connector>;

export default connector(Widgets);
