import { Channels } from '@eeedo/types';
import iziToast from 'izitoast';
import moment from 'moment';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { Dropdown, Form, Icon, Message } from 'semantic-ui-react';
import * as yup from 'yup';

import type { Field, FieldSet, ISalesforceSettingsData, PersonalData } from '@eeedo/types';
import type { WithTranslation } from 'react-i18next';
import type { DropdownProps } from 'semantic-ui-react';

import { getSalesforceObjectFieldsByType } from '../Utilities/salesforce';
import ReplyControlButtons from './components/ReplyControlButtons';
import { ReplyMethod } from './ReplyMethod';
import SalesforceFormField from './Salesforce/SalesforceFormField';
import SalesforceObjectForm from './Salesforce/SalesforceObjectForm';
import { salesforceValidationSchemasMap } from './Salesforce/schemas';
import EnvSettings from 'src/api/EnvSettings';
import SalesforceApi from 'src/api/SalesforceApi';

import type { Option } from '../MultiSelectInput/MultiSelectInput';
import type { ReplyMethodProps } from './ReplyMethod';
import type { IGeneralSalesforceData, SalesforceFormPayload, SalesforceObjectType } from 'src/types/Salesforce';
import type { Ticket } from 'src/types/Ticket';

interface ReplySalesforceProps extends ReplyMethodProps<ReplySalesforceState>, WithTranslation {
  task: Ticket;
  userData: PersonalData;

  fieldSet?: FieldSet;
  drafts?: Partial<ReplySalesforceState>;
}

export interface ReplySalesforceState {
  isLoading: boolean;
  isSubmitting: boolean;
  contacts: IGeneralSalesforceData[];
  accounts: IGeneralSalesforceData[];
  users: IGeneralSalesforceData[];
  payload: SalesforceFormPayload;
  validationErrors: { [key: string]: any };
  salesforceSettings: ISalesforceSettingsData;
}

export class ReplySalesforce extends ReplyMethod<ReplySalesforceProps, ReplySalesforceState> {
  constructor(props: ReplySalesforceProps) {
    super(props);

    this.state = this.getInitialState(this.props.drafts);

    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  getDraftChannel(): Channels {
    return Channels.salesforce;
  }

  getDraftState(): Partial<ReplySalesforceState> {
    return {
      payload: this.state.payload
    };
  }

  private getInitialState = (drafts?: Partial<ReplySalesforceState>): ReplySalesforceState => ({
    isLoading: false,
    isSubmitting: false,
    contacts: [],
    accounts: [],
    users: [],
    payload: {
      ...drafts?.payload
    },
    validationErrors: {},
    salesforceSettings: EnvSettings.getSettings().SALESFORCE.data
  });

  private handleSetState = (fields: Partial<SalesforceFormPayload>) => {
    this.setState(
      {
        payload: {
          ...this.state.payload,
          ...fields
        }
      },
      () => {
        this.saveDraft(this.state);
      }
    );
  };

  handleSelectChange(property: string, value: string) {
    this.handleSetState({
      [property]: value
    });
  }

  public clearFields = () =>
    this.setState(
      {
        payload: {}
      },
      () => {
        this.saveDraft(this.state);
      }
    );

  private showErrorToast = (message: string) => {
    const { t } = this.props;
    iziToast.error({
      title: `${t('ERROR')}!`,
      icon: 'icon delete',
      message,
      timeout: 7500
    });
  };

  private createTask = async () => {
    const {
      payload: { Subject, Description, Status, WhoId, OwnerId, ActivityDate },
      salesforceSettings: {
        dueDateInSeconds,
        fieldsMapping: { case: caseMapping }
      }
    } = this.state;

    return SalesforceApi.createTask({
      ticketId: this.props.task.id,
      payload: {
        ...(!!Subject && { [caseMapping.Subject]: Subject }),
        ...(!!Description && { [caseMapping.Description]: Description }),
        ...(!!Status && { [caseMapping.Status]: Status }),
        ...(!!ActivityDate && {
          [caseMapping.ActivityDate]: dueDateInSeconds ? ActivityDate : ActivityDate * 1000
        }),
        ...(!!WhoId && { [caseMapping.WhoId]: WhoId }),
        ...(!!OwnerId && { [caseMapping.OwnerId]: OwnerId })
      }
    });
  };

  private createCase = async () => {
    const {
      payload: { Subject, Description, Status, WhoId, OwnerId, ActivityDate },
      salesforceSettings: {
        dueDateInSeconds,
        fieldsMapping: { task: taskMapping }
      }
    } = this.state;

    return SalesforceApi.createCase({
      ticketId: this.props.task.id,
      payload: {
        ...(!!Subject && { [taskMapping.Subject]: Subject }),
        ...(!!Description && { [taskMapping.Description]: Description }),
        ...(!!Status && { [taskMapping.Status]: Status }),
        ...(!!ActivityDate && {
          [taskMapping.ActivityDate]: dueDateInSeconds ? ActivityDate : ActivityDate * 1000
        }),
        ...(!!WhoId && { [taskMapping.WhoId]: WhoId }),
        ...(!!OwnerId && { [taskMapping.OwnerId]: OwnerId })
      }
    });
  };

  private createLead = async () => {
    const {
      payload: { Name, Description, Status, Company, OwnerId },
      salesforceSettings: {
        fieldsMapping: { lead: leadMapping }
      }
    } = this.state;

    return SalesforceApi.createLead({
      ticketId: this.props.task.id,
      payload: {
        ...(!!Name && { [leadMapping.Name]: Name }),
        ...(!!Description && { [leadMapping.Description]: Description }),
        ...(!!Status && { [leadMapping.Status]: Status }),
        ...(!!Company && { [leadMapping.Company]: Company }),
        ...(!!OwnerId && { [leadMapping.OwnerId]: OwnerId })
      }
    });
  };

  private createOpportunity = async () => {
    const {
      payload: { Name, Description, StageName, CloseDate, OwnerId, Amount, AccountId },
      salesforceSettings: {
        closeDateInSeconds,
        fieldsMapping: { opportunity: opportunityMapping }
      }
    } = this.state;

    return SalesforceApi.createOpportunity({
      ticketId: this.props.task.id,
      payload: {
        Name,
        ...(!!Name && { [opportunityMapping.Name]: Name }),
        ...(!!Description && { [opportunityMapping.Description]: Description }),
        ...(!!StageName && { [opportunityMapping.StageName]: StageName }),
        ...(!!CloseDate && {
          [opportunityMapping.CloseDate]: closeDateInSeconds ? CloseDate : CloseDate * 1000
        }),
        ...(!!Amount && { [opportunityMapping.Amount]: Amount }),
        ...(!!AccountId && { [opportunityMapping.AccountId]: AccountId }),
        ...(!!OwnerId && { [opportunityMapping.OwnerId]: OwnerId })
      }
    });
  };

  private createEmails = async () => {
    const { t } = this.props;
    const {
      payload: { Emails, Description },
      contacts
    } = this.state;
    const logAnEmailRequests =
      Emails?.map(
        async (providedCustomer) =>
          await SalesforceApi.logAnEmail({
            ticketId: this.props.task.id,
            payload: {
              WhoId: contacts.find((contact: IGeneralSalesforceData) => contact.Email === providedCustomer.value)?.Id,
              Subject: Emails?.map((email: Option) => email.value).join(', '),
              Description
            }
          })
      ) || [];

    Emails?.map(async (singeCustomer) => {
      const foundContactToLog = contacts.find(
        (contact: IGeneralSalesforceData) => contact.Email === singeCustomer?.value
      );

      if (!foundContactToLog)
        iziToast.error({
          title: `${t('ERROR')}!`,
          icon: 'icon delete',
          message: `Record not created for ${singeCustomer.value}. Not found such contact`,
          timeout: 7500
        });
    });

    await Promise.all(logAnEmailRequests);
  };

  private createCall = async () => {
    const { t } = this.props;
    const {
      payload: { Phones, Description },
      contacts
    } = this.state;
    const logACallRequests =
      Phones?.map(
        async (providedCustomer) =>
          await SalesforceApi.logACall({
            ticketId: this.props.task.id,
            payload: {
              WhoId: contacts.find((contact: IGeneralSalesforceData) => contact.Phone === providedCustomer.value)?.Id,
              Subject: Phones?.map((phone: Option) => phone.value).join(', '),
              Description
            }
          })
      ) || [];

    Phones?.map(async (singeCustomer) => {
      const foundContactToLog = contacts.find(
        (contact: IGeneralSalesforceData) => contact.Phone === singeCustomer.value
      );

      if (!foundContactToLog)
        iziToast.error({
          title: `${t('ERROR')}!`,
          icon: 'icon delete',
          message: `Record not created for ${singeCustomer.value}. Not found such contact`,
          timeout: 7500
        });
    });

    await Promise.all(logACallRequests);
  };

  private createObject = async () => {
    const {
      payload: { type, ...body }
    } = this.state;

    if (!type) {
      throw new Error('Object type is not set');
    }

    const entityId = this.props.task?.entities[0]?._id;
    if (!entityId) {
      throw new Error('Entity is not attached');
    }

    const objectIdentifierFieldName = this.state.salesforceSettings.objects?.[type];
    if (!objectIdentifierFieldName) {
      throw new Error(`No object identifier field is set for the ${type} object type`);
    }

    const data = {
      ...body,
      ticketId: this.props.task.id,
      [objectIdentifierFieldName]: entityId
    };

    return SalesforceApi.createStandardObject(type, data).catch((err) => {
      console.error(err);
      throw err;
    });
  };

  private createMethodsMap: Partial<Record<SalesforceObjectType, () => Promise<any>>> = {
    Case: this.createCase,
    Task: this.createTask,
    Lead: this.createLead,
    Opportunity: this.createOpportunity,
    Email: this.createEmails,
    Call: this.createCall
  };

  private buildObjectSchema = () => {
    const { payload, salesforceSettings } = this.state;
    const { fieldSet } = this.props;
    if (!fieldSet || !payload.type) {
      return yup.object().shape({});
    }

    const fields = getSalesforceObjectFieldsByType(payload.type, fieldSet, salesforceSettings);
    const fieldsSchemasMap = fields.reduce((schemasMap, field) => {
      schemasMap[field.value] = yup.string();
      return schemasMap;
    }, {} as Partial<Record<string, yup.AnyObjectSchema>>);

    return yup.object().shape(fieldsSchemasMap);
  };

  public submitComment = async () => {
    const { t } = this.props;
    if (this.state.isSubmitting || !Object.keys(this.state.payload).length) {
      return;
    }

    this.setState({ isSubmitting: true, validationErrors: {} }, () => {
      const { payload, salesforceSettings } = this.state;
      const validationSchema = salesforceSettings.standardObjects
        ? this.buildObjectSchema()
        : salesforceValidationSchemasMap[payload.type!] ?? yup.object().shape({});

      validationSchema
        .validate(payload, { abortEarly: false })
        .then(async () => {
          const createMethod = salesforceSettings.standardObjects
            ? this.createObject
            : this.createMethodsMap[payload.type!] ?? (() => Promise.resolve());
          await createMethod();

          this.clearFields();
          this.setState({ isSubmitting: false });

          iziToast.success({
            title: t('OK'),
            icon: 'icon check',
            message: 'Record was created',
            timeout: 5000
          });
        })
        .catch((err) => {
          if (err.inner?.length) {
            err.inner.forEach((error: any) => {
              if (error.path) {
                this.setState({
                  validationErrors: {
                    ...this.state.validationErrors,
                    [error.path]: error.message
                  }
                });
              }
            });
          } else {
            this.showErrorToast('Failed to create Salesforce record');
          }

          this.setState({ isSubmitting: false });
        });
    });
  };

  private getInitialFormPayload = async (formType: SalesforceObjectType) => {
    switch (formType) {
      case 'Task': {
        if (this.props.task.entities[0]?._id) {
          await this.setSearchableSelectFields('Contact', this.props.task.entities[0]._id);
        }

        if (this.state.salesforceSettings.taskDefaultOwnerId) {
          await this.setSearchableSelectFields('User', this.state.salesforceSettings.taskDefaultOwnerId);
        }

        return {
          WhoId: this.props.task.entities[0]?._id ?? '',
          OwnerId: this.state.salesforceSettings.taskDefaultOwnerId,
          Status: this.state.salesforceSettings.taskDefaultStatus
        };
      }

      case 'Lead': {
        if (this.state.salesforceSettings.leadDefaultOwnerId) {
          await this.setSearchableSelectFields('User', this.state.salesforceSettings.leadDefaultOwnerId);
        }

        return {
          OwnerId: this.state.salesforceSettings.leadDefaultOwnerId,
          Status: this.state.salesforceSettings.leadDefaultStatus
        };
      }

      case 'Opportunity': {
        if (this.props.task.entities[0]?.data?.Account?.data[0].Id) {
          await this.setSearchableSelectFields('Account', this.props.task.entities[0].data.Account.data[0].Id);
        }

        if (this.state.salesforceSettings.opportunityDefaultOwnerId) {
          await this.setSearchableSelectFields('User', this.state.salesforceSettings.opportunityDefaultOwnerId);
        }

        return {
          CloseDate: moment().add(30, 'days').unix(),
          AccountId: this.props.task.entities[0]?.data?.Account?.data[0].Id ?? '',
          OwnerId: this.state.salesforceSettings.opportunityDefaultOwnerId,
          StageName: this.state.salesforceSettings.opportunityDefaultStage
        };
      }

      case 'Email': {
        await this.setSearchableSelectFields('Contact', '', true);

        const defaultEmails = this.props.task.entities
          .map((singleEntity) => {
            if (singleEntity.data?.Email) {
              return {
                label: singleEntity.data.Email,
                value: singleEntity.data.Email
              };
            }
            return undefined;
          })
          .filter((singleEntity) => !!singleEntity);

        return { Emails: defaultEmails };
      }

      case 'Call': {
        await this.setSearchableSelectFields('Contact', '', true);

        const defaultPhones = this.props.task.entities
          .map((singleEntity) => {
            if (singleEntity.data?.Phone) {
              return {
                label: singleEntity.data.Phone,
                value: singleEntity.data.Phone
              };
            }

            if (singleEntity.data?.MobilePhone) {
              return {
                label: singleEntity.data.Phone,
                value: singleEntity.data.Phone
              };
            }
            return undefined;
          })
          .filter((singleEntity) => !!singleEntity);

        return { Phones: defaultPhones };
      }

      default: {
        return {};
      }
    }
  };

  private setSearchableSelectFields = async (
    type: SalesforceObjectType,
    stringToSearch: string,
    allowFullList?: boolean
  ) => {
    const { t } = this.props;
    if (stringToSearch.length < 3 && !allowFullList) return;

    if (allowFullList) {
      this.setState({ isLoading: true });
    }

    switch (type) {
      case 'Contact': {
        await SalesforceApi.getContacts(stringToSearch)
          .then((contacts) => {
            this.setState({
              contacts: Array.isArray(contacts) ? contacts : [],
              isLoading: false
            });
          })
          .catch(() =>
            iziToast.error({
              title: `${t('ERROR')}!`,
              icon: 'icon delete',
              message: 'Cannot fetch contacts',
              timeout: 7500
            })
          );
        break;
      }

      case 'Account': {
        await SalesforceApi.getAccounts(stringToSearch)
          .then((accounts) => {
            this.setState({
              accounts,
              isLoading: false
            });
          })
          .catch(() =>
            iziToast.error({
              title: `${t('ERROR')}!`,
              icon: 'icon delete',
              message: 'Cannot fetch accounts',
              timeout: 7500
            })
          );
        break;
      }

      case 'User': {
        await SalesforceApi.getUsers(stringToSearch)
          .then((users) => {
            this.setState({
              users,
              isLoading: false
            });
          })
          .catch(() =>
            iziToast.error({
              title: `${t('ERROR')}!`,
              icon: 'icon delete',
              message: 'Cannot fetch users',
              timeout: 7500
            })
          );
        break;
      }
      default:
        break;
    }
  };

  private onChooseTypeChange = async (_event: React.SyntheticEvent, data: DropdownProps) => {
    const initialPayload: { [key: string]: any } = await this.getInitialFormPayload(data.value as SalesforceObjectType);

    this.setState(
      {
        payload: { ...initialPayload }
      },
      () =>
        this.handleSetState({
          type: data.value as SalesforceObjectType
        })
    );
  };

  private handleObjectFormFieldChange = (field: Field, value: string) => {
    const update: Partial<SalesforceFormPayload> = {
      [field.value]: value
    };

    if (field.params?.dependentFields?.length) {
      for (const fieldName of field.params.dependentFields as string[]) {
        update[fieldName] = undefined;
      }
    }

    this.handleSetState(update);
  };

  render() {
    const { fieldSet, t } = this.props;
    const { payload, isLoading, isSubmitting, users, accounts, contacts, validationErrors, salesforceSettings } =
      this.state;

    return (
      <Form reply={true} style={{ marginTop: '20px' }}>
        {!isLoading ? (
          <>
            <Form.Field>
              <label>{t('salesforce_reply.choose_type')}</label>

              <Dropdown
                fluid
                search
                selection
                options={Object.keys(salesforceSettings.objects ?? {}).map((text) => ({ text, value: text }))}
                value={payload.type || ''}
                onChange={this.onChooseTypeChange}
              />
            </Form.Field>

            {payload.type && (
              <>
                {salesforceSettings.standardObjects && fieldSet && (
                  <SalesforceObjectForm
                    type={payload.type}
                    fieldSet={fieldSet}
                    values={this.state.payload}
                    settings={salesforceSettings}
                    setValue={this.handleObjectFormFieldChange}
                  />
                )}
                {!salesforceSettings.standardObjects && (
                  <SalesforceFormField
                    payload={payload}
                    users={users}
                    accounts={accounts}
                    contacts={contacts}
                    validationErrors={validationErrors}
                    salesforceSettings={salesforceSettings}
                    handleSetState={this.handleSetState}
                    handleSelectChange={this.handleSelectChange}
                    setSearchableSelectFields={this.setSearchableSelectFields}
                  />
                )}
              </>
            )}

            <ReplyControlButtons
              internal
              small={this.props.smallButtons}
              disabled={isSubmitting || !Object.keys(payload).length}
              loading={isSubmitting}
              onClear={this.clearFields}
              onSubmit={this.submitComment}
            />
          </>
        ) : (
          <Message icon info>
            <Icon name="circle notched" loading />
            <Message.Content>
              <Message.Header>{t('salesforce_reply.loading')}</Message.Header>
              {t('salesforce_reply.fetching_data')}
            </Message.Content>
          </Message>
        )}
      </Form>
    );
  }
}

export default withTranslation()(ReplySalesforce);
