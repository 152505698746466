import iziToast from 'izitoast';
import { get } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';

import type { Channel, SenderEmail, TicketType, UserWithProfile } from '@eeedo/types';
import type { FC } from 'react';

import ChannelType from '../CommentIconContent/ChannelType';
import CommentHeader from '../generic/CommentHeader/CommentHeader';
import UserAvatar from '../User/UserAvatar';
import UserPopup from '../User/UserPopup';
import CommentText from './CommentText';
import ExternalCommentPopup from './ExternalCommentPopup';
import { getCommentSender } from 'src/Components/Utilities/comments';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import { iconName } from 'src/Utilities/iconName';

import type { Ticket, Comment as TicketComment } from 'src/types/Ticket';

import './UserComment.css';

interface UserCommentSingleLineProps extends TicketComment {
  user?: UserWithProfile;
  task: Ticket;
  taskId: string;
  channels: Channel[];
  title: string | null;
  senderEmails: SenderEmail[];
  ticketTypes: TicketType[];
  botButtonClickedState: undefined | TicketComment;
  isLastComment?: boolean;
  isLastExternalComment?: boolean;
}

const UserCommentSingleLine: FC<UserCommentSingleLineProps> = (props) => {
  const { t } = useTranslation();
  const [isIntegratorUserReply, setIntegratorUserReply] = useState(false);
  const [chatComment, setChatComment] = useState(false);
  const [, setChannelTypeName] = useState<string>('');

  useEffect(() => {
    /**
     * Check if incoming comment comes from integrator
     */
    if (
      [ChannelType.Chat, ChannelType.Facebook, ChannelType.Giosg, ChannelType.CustomerPortal].includes(
        props.channel!
      ) &&
      props.direction === 'in' &&
      props.task.entities.length
    ) {
      const targetEntity = props.task.entities[0].data;

      if (targetEntity?.firstName || targetEntity?.lastName) {
        setIntegratorUserReply(true);
      }
    }
  }, [props.channel, props.direction, props.task.entities.length]);

  useEffect(() => {
    const channelTypeToName = {
      [ChannelType.Email]: 'email',
      [ChannelType.Sms]: 'sms',
      [ChannelType.Internal]: 'internal',
      [ChannelType.Chat]: 'chat',
      [ChannelType.Webform]: 'webform',
      [ChannelType.Other]: 'other'
    };

    try {
      setChannelTypeName(channelTypeToName[props.channel ?? ChannelType.Other]);
    } catch (_) {
      setChannelTypeName('other');
    }

    setChatComment(
      ['customer_closed_chat', 'agent_closed_chat', 'chat_was_closed_automatically', 'user_closed_chat'].some(
        (tag) => props.content?.indexOf(`[${tag}]`) === 0
      )
    );
  }, []);

  const getCustomerInfoFields = () =>
    props.ticketTypes
      .find((ticketType) => ticketType.name === props.task.taskType)
      ?.fieldSets?.find((fieldSet) => fieldSet.id === 'customerInfo')?.customerInfo ?? [];

  const getChannelData = (channelId: number) => {
    return props.channels.find((channel) => channel.id === channelId);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    iziToast.info({
      message: t('COPIED')
    });
  };

  const { user } = props;
  const channelData = getChannelData(props.channel!);
  const originalEntityData = get(props, ['metaData', 'originalEntity'], null);

  /*const helperComments = props.task.comments.filter(
    (comment) => comment.type === 'helper' && comment.metaData?.parentCommentId === parseInt(props.id.substring(3), 10)
  );*/

  let commentSender: string = getCommentSender({
    originalEntityData,
    channelData,
    user,
    metaData: props.metaData ?? null,
    direction: props.direction ?? null,
    ticketEntities: props.task.entities,
    customerInfoFields: getCustomerInfoFields()
  });

  if (['CHAT_ENTITY', 'FORM_ENTITY', 'TAMPUURI_ENTITY', 'UNKNOWN'].includes(commentSender)) {
    commentSender = t(`COMMENT_AUTHOR_${commentSender}`);
  }

  const renderAvatar = (user: UserWithProfile) =>
    ![ChannelType.Internal, ChannelType.Other].includes(props.channel!) && props.direction === 'in' ? (
      <ExternalCommentPopup
        channelId={props.channel!}
        commentId={props.id}
        metaData={props.metaData}
        ticketTypes={props.ticketTypes}
        channels={props.channels}
        title={props.title}
      >
        <div
          style={{
            width: '32px',
            height: '32px',
            textAlign: 'center',
            paddingLeft: '4px'
          }}
        >
          <Icon
            name={iconName(channelData, props.direction!)}
            className="icon-small"
            style={{ color: channelData?.color || '#000', fontSize: '1.55rem' }}
          />
        </div>
      </ExternalCommentPopup>
    ) : (
      <UserPopup UID={user.UID} botName={props.metaData?.bot?.name} disabled={isIntegratorUserReply}>
        <UserAvatar user={user} size="32" round />
      </UserPopup>
    );

  return (
    <div>
      <CommentHeader
        avatar={user ? renderAvatar(user) : null}
        name={<span onClick={() => copyToClipboard(commentSender)}>{commentSender}</span>}
        date={getPrettyDate(props.created, { format: DATE_TIME_FORMAT })}
        compact
        description={
          <CommentText
            parsedContent={props.truncatedContent ?? ''}
            isChatComment={chatComment}
            isCommentExpanded={false}
            isLastComment={!!props.isLastComment}
            botButtonClickedState={props.botButtonClickedState}
            metaData={props.metaData}
            type={props.type!}
            attachments={props.task.attachments}
            toggleExpand={() => {}}
          />
        }
      />

      {/* what to do here?*/}
      {/* disabled for now
      {!!helperComments.length && (
        <Comment.Group className="discussionPanel__helpers discussionPanel__helpers--single-line">
          {helperComments.map((comment) => (
            <HelperComment
              comment={{ ...comment, content: truncateCommentContent(comment.content ?? '') }}
              compact
              key={comment.id}
            />
          ))}
        </Comment.Group>
      )}
      */}
    </div>
  );
};

export default UserCommentSingleLine;
