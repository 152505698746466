import React from 'react';
import { useTranslation } from 'react-i18next';

import type { ComponentProps, FC } from 'react';

import Button from 'src/Components/generic/Button/Button';
import Dropdown from 'src/Components/generic/Dropdown/Dropdown';
import { selectSelectedTicketsCount } from 'src/selectors/rootStateSelectors';
import { useAppSelector } from 'src/store';

import './SingleLineActions.css';

interface SingleLineActionsProps {
  options: ComponentProps<typeof Dropdown>['options'];
}

const SingleLineActions: FC<SingleLineActionsProps> = ({ options }) => {
  const { t } = useTranslation();
  const permissions = useAppSelector((state) => state.userData.permissions);
  const selectedTicketsCount = useAppSelector(selectSelectedTicketsCount);

  return (
    <Dropdown
      icon=""
      options={options}
      className="single-line-actions"
      disabled={selectedTicketsCount < 1 || !permissions.includes('updateContent')}
      trigger={<Button type="primary">{t('Perform on Selected')}</Button>}
    />
  );
};

export default React.memo(SingleLineActions);
