import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { DropdownProps } from 'semantic-ui-react';

import AccordionHeader from '../AccordionHeader';
import PriorityDropdown from 'src/Components/generic/PriorityDropdown';

import type { Ticket } from 'src/types/Ticket';

interface PriorityProps {
  id: string;
  task: Ticket;
  widgetOpen: boolean;
  ind: number;

  handlePriorityChange(priority: number): void;
}

type OnChangeCallback = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void;

const PriorityWidget = ({ id, task, widgetOpen, ind, handlePriorityChange }: PriorityProps) => {
  const { t } = useTranslation();

  const onChange = useCallback<OnChangeCallback>(
    (_event, data) => {
      handlePriorityChange(data.value as number);
    },
    [handlePriorityChange]
  );

  return (
    <AccordionHeader
      id={id}
      as="h4"
      active={widgetOpen}
      title={t('CASE_PRIORITY')}
      icon="inbox"
      key={`case-priority-${ind}`}
      isWidget={true}
    >
      <PriorityDropdown value={task.priority} id="priorityDropdown" onChange={onChange} />
    </AccordionHeader>
  );
};

export default React.memo(PriorityWidget);
