import React, { createContext, useContext, useState } from 'react';

import type { FunctionComponent } from 'react';

interface DiscussionContextType {
  isEnableReplyExpanding: boolean;

  toggleEnableReplyExpanding: () => void;
}

const DiscussionContext = createContext<DiscussionContextType | undefined>(undefined);

export const useDiscussionContext = () => {
  const context = useContext(DiscussionContext);
  if (context === undefined) {
    throw new Error('useDiscussionContext must be used within a DiscussionProvider');
  }
  return context;
};

export const DiscussionProvider: FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const [isEnableReplyExpanding, setReplyExpanding] = useState<boolean>(true);

  const toggleEnableReplyExpanding = () => setReplyExpanding((prevState) => !prevState);

  return (
    <DiscussionContext.Provider value={{ isEnableReplyExpanding, toggleEnableReplyExpanding }}>
      {children}
    </DiscussionContext.Provider>
  );
};
