import { Channels } from '@eeedo/types';
import { throttle } from 'lodash-es';
import React from 'react';
import { Translation } from 'react-i18next';
import { Form } from 'semantic-ui-react';

import type { PersonalData, ResponseTemplate, TicketType } from '@eeedo/types';

import ReplyControlButtons from './components/ReplyControlButtons';
import ReplyVCDrafts from './components/ReplyVCDrafts';
import { KnowledgeBank } from './KnowledgeBank';
import { ReplyMethod } from './ReplyMethod';
import ReplyTemplates from './ReplyTemplates';
import ReplyTextArea from './ReplyTextArea';
import FeatureFlags from 'src/api/FeatureFlags';
import ChannelType from 'src/Components/CommentIconContent/ChannelType';
import { Roles } from 'src/types/User';
import { insertAtCursor } from 'src/Utilities/insertAtCursor';

import type { ReplyMethodProps } from './ReplyMethod';
import type { KeyDownEvent } from 'src/Components/Case/ReplyEditor';
import type { TicketStatusAfterCommentSubmit } from 'src/types/Salesforce';
import type { Entity, Ticket } from 'src/types/Ticket';

interface ReplyChatProps extends ReplyMethodProps<ReplyChatState> {
  userData: PersonalData;
  ticketType: TicketType;
  templates: ResponseTemplate[];
  drafts?: ReplyChatState;
  task: Ticket;
  entities: Entity[];
  ticketTypes: TicketType[];
  canCloseAsDone: boolean;

  // TODO: typing needed
  setChatAnchor: (...args: any[]) => any;
  onSubmit: (...args: any[]) => any;
  updateTypingStatusToCustomer: (...args: any[]) => any;
  changeTicketStatus: (ticketId: string, uid: string, status: TicketStatusAfterCommentSubmit) => Promise<void>;
}

interface ReplyChatState {
  content: string;
  isLoading?: boolean;
  endChatAfterSubmit?: boolean;
  selectedReplyTemplate?: string;
}

class ReplyChat extends ReplyMethod<ReplyChatProps, ReplyChatState> {
  private channel = ChannelType.Chat;
  private editor: HTMLTextAreaElement | null;

  constructor(props: ReplyChatProps) {
    super(props);
    this.state = this.getInitialState(this.props.drafts);
    this.updateTypingStatusToCustomer = throttle(this.updateTypingStatusToCustomer, 5000).bind(this);
  }

  componentWillReceiveProps(nextProps: ReplyChatProps) {
    if (this.props.taskId !== nextProps.taskId) {
      this.setState(this.getInitialState(nextProps.drafts));
    }
    if (this.props.drafts?.content !== nextProps.drafts?.content) {
      this.setState({ content: nextProps.drafts?.content || '' });
    }
  }

  getDraftChannel(): Channels {
    return Channels.chat;
  }

  getDraftState(state: ReplyChatState): Partial<ReplyChatState> {
    return {
      content: state.content,
      selectedReplyTemplate: state.selectedReplyTemplate
    };
  }

  private getInitialState = (drafts: ReplyChatState | undefined) => {
    const state: ReplyChatState = {
      isLoading: false,
      content: drafts?.content || '',
      endChatAfterSubmit: drafts?.endChatAfterSubmit || false,
      selectedReplyTemplate: drafts?.selectedReplyTemplate || undefined
    };
    return state;
  };

  public submitComment = async (ticketStatus?: TicketStatusAfterCommentSubmit) => {
    const { profile } = this.props.userData;

    if (this.state.isLoading || !this.state.content.length) {
      return;
    }

    const bodyOfRequest = {
      content: this.state.content,
      sendAsMail: false,
      sendAsChatMessage: true,
      channel: this.channel,
      nickName: profile.chatAlias || profile.firstName,
      chat: this.props.task.case,
      direction: 'out',
      closeChat: this.state.endChatAfterSubmit
    };
    this.setState({ isLoading: true }, () => {
      this.saveDraft(this.state);
      const request = this.props.onSubmit(bodyOfRequest, false);
      request
        .then((response: any) => {
          if (response !== false) {
            this.setState({ content: '', isLoading: false }, () => {
              this.saveDraft(this.state);
            });
            if (typeof ticketStatus !== 'undefined') {
              this.props.changeTicketStatus(this.props.taskId, this.props.userData.UID, ticketStatus);
            }
          } else {
            this.setState({ isLoading: false });
          }
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    });
  };

  private clearFields = () => {
    this.setState({ content: '', selectedReplyTemplate: undefined }, () => {
      this.saveDraft(this.state);
    });
  };

  private handleChangeContent = (content: string) => {
    this.setState({ content }, () => {
      this.saveDraft(this.state);
    });
  };

  private updateTypingStatusToCustomer = () => {
    if (!FeatureFlags.isFlagOn('CHAT_SHOW_AGENT_TYPING_STATUS')) {
      return;
    }

    this.props.updateTypingStatusToCustomer(this.props.task.case.connectionId);
  };

  private editorKeyDown = (event: KeyDownEvent) => {
    this.handleHotKeys(event);
    this.updateTypingStatusToCustomer();
  };

  render() {
    return (
      <Translation ns="translations">
        {(t) => (
          <Form reply={true} style={{ marginTop: '20px' }}>
            <Form.Field>
              <label>{t('ADD_COMMENT_CANNED_RESPONSE')}</label>
              <ReplyTemplates
                channel={this.channel}
                userData={this.props.userData}
                ticketType={this.props.ticketType}
                templates={this.props.templates}
                task={this.props.task}
                selectedOption={this.state.selectedReplyTemplate}
                setSelectedOption={(value) => this.setState({ selectedReplyTemplate: value })}
                setContent={(value) => this.updateState({ ...value })}
                insertAtCursor={(value, content) => {
                  value.content = insertAtCursor(this.editor, content);
                  this.updateState({ ...value });
                }}
                content={this.state.content}
                entities={this.props.entities}
                discardHtml={true}
              />
            </Form.Field>
            {FeatureFlags.isFlagOn('ENABLE_EGAIN') && (
              <Form.Field>
                <KnowledgeBank
                  title={this.state.content}
                  id={this.props.taskId}
                  extraArguments={this.props.ticketType.knowledgeBank}
                  content={this.state.content}
                  updateState={(value: string) => {
                    this.updateState({ content: value });
                  }}
                />
              </Form.Field>
            )}
            <ReplyVCDrafts taskId={this.props.taskId} channelId={this.channel} onClickLoad={this.loadVCDraft} />
            <Form.Field id="commentContentField">
              <label>{t('ADD_COMMENT_CONTENT')}</label>
              <ReplyTextArea
                ref={(ref) => (this.editor = ref)}
                content={this.state.content}
                onChange={this.handleChangeContent}
                onKeyDown={this.editorKeyDown}
              />
            </Form.Field>
            <Form.Group inline>
              <Form.Checkbox
                toggle={true}
                onClick={(event, data) => this.updateState({ endChatAfterSubmit: data.checked })}
                checked={this.state.endChatAfterSubmit}
                label={t('ADD_COMMENT_CLOSE_CHAT')}
              />
              <Form.Checkbox
                style={{ paddingLeft: '27px' }}
                toggle={true}
                onClick={() => {
                  this.props.setChatAnchor(!Roles.isChatAnchored(this.props.userData));
                }}
                checked={Roles.isChatAnchored(this.props.userData)}
                label={t('ANCHOR_CHAT')}
              />
            </Form.Group>
            <ReplyControlButtons
              small={this.props.smallButtons}
              disabled={this.state.isLoading || !this.state.content.length}
              disabledCloseAsDone={!this.props.canCloseAsDone}
              loading={this.state.isLoading}
              onClear={this.clearFields}
              onSaveDraft={() => this.saveVCDraft(false)}
              onSubmit={() => this.submitComment()}
              onSubmitAndTicketStatusChange={(ticketStatus?: TicketStatusAfterCommentSubmit) =>
                this.submitComment(ticketStatus)
              }
            />
          </Form>
        )}
      </Translation>
    );
  }
}

export default ReplyChat;
