import { Channels } from '@eeedo/types';

import FeatureFlags from 'src/api/FeatureFlags';
import ChannelType from 'src/Components/CommentIconContent/ChannelType';

interface TabIndexListItem {
  name: Channels;
  index: ChannelType;
}

export const getCaseTabIndexList = (allowedChannels: number[]): TabIndexListItem[] => {
  const tabIndexList: TabIndexListItem[] = [];

  if (allowedChannels.includes(ChannelType.Internal)) {
    tabIndexList.push({
      name: Channels.intra,
      index: tabIndexList.length
    });
  }

  if (allowedChannels.includes(ChannelType.Email)) {
    tabIndexList.push({
      name: Channels.email,
      index: tabIndexList.length
    });
  }

  if (allowedChannels.includes(ChannelType.Chat)) {
    tabIndexList.push({
      name: Channels.chat,
      index: tabIndexList.length
    });
  }

  if (allowedChannels.includes(ChannelType.Sms)) {
    tabIndexList.push({
      name: Channels.sms,
      index: tabIndexList.length
    });
  }

  if (FeatureFlags.isFlagOn('SECURE_MAIL_ENABLED') === true && allowedChannels.includes(ChannelType.SecureEmail)) {
    tabIndexList.push({
      name: Channels.secureMail,
      index: tabIndexList.length
    });
  }

  if (FeatureFlags.isFlagOn('FACEBOOK_CHAT_ENABLED') === true && allowedChannels.includes(ChannelType.Facebook)) {
    tabIndexList.push({
      name: Channels.facebook,
      index: tabIndexList.length
    });
  }

  if (FeatureFlags.isFlagOn('GIOSG_CHAT_ENABLED') === true && allowedChannels.includes(ChannelType.Giosg)) {
    tabIndexList.push({
      name: Channels.giosg,
      index: tabIndexList.length
    });
  }

  if (
    FeatureFlags.isFlagOn('CUSTOMER_PORTAL_ENABLED') === true &&
    allowedChannels.includes(ChannelType.CustomerPortal)
  ) {
    tabIndexList.push({
      name: Channels.customerPortal,
      index: tabIndexList.length
    });
  }

  if (allowedChannels.includes(ChannelType.WhatsApp)) {
    tabIndexList.push({
      name: Channels.whatsapp,
      index: tabIndexList.length
    });
  }

  if (allowedChannels.includes(ChannelType.Salesforce)) {
    tabIndexList.push({
      name: Channels.salesforce,
      index: tabIndexList.length
    });
  }

  if (allowedChannels.includes(ChannelType.D365)) {
    tabIndexList.push({
      name: Channels.d365,
      index: tabIndexList.length
    });
  }

  if (allowedChannels.includes(ChannelType.Tampuuri)) {
    tabIndexList.push({
      name: Channels.tampuuri,
      index: tabIndexList.length
    });
  }

  if (allowedChannels.includes(ChannelType.Phone)) {
    tabIndexList.push({
      name: Channels.phone,
      index: tabIndexList.length
    });
  }

  if (allowedChannels.includes(ChannelType.Eezy)) {
    tabIndexList.push({
      name: Channels.eezy,
      index: tabIndexList.length
    });
  }

  if (allowedChannels.includes(ChannelType.ViestiTytti)) {
    tabIndexList.push({
      name: Channels.viestitytti,
      index: tabIndexList.length
    });
  }

  if (allowedChannels.includes(ChannelType.Instagram)) {
    tabIndexList.push({
      name: Channels.instagram,
      index: tabIndexList.length
    });
  }

  return tabIndexList;
};
