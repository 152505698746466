import { createAsyncThunk } from '@reduxjs/toolkit';

import type { BatchContentUpdate } from '@eeedo/types';

import TicketsApi from 'src/api/TicketsApi';
import { resetSelectedTickets, setInProgress } from 'src/reducers/singleLineViewReducer';

import type { State } from 'src/types/initialState';
import type { Ticket } from 'src/types/Ticket';

export const updateSelectedTasks = createAsyncThunk<void, Partial<Ticket>, { state: State }>(
  'singleLineView/updateSelectedTasks',
  async (update, { dispatch, getState }) => {
    const selected = getState().singleLineView.selected;

    const updates: BatchContentUpdate[] = [];
    for (const id in selected) {
      if (selected[id]) {
        updates.push({ ...update, id });
        dispatch(setInProgress({ id, inProgress: true }));
      }
    }

    try {
      await TicketsApi.batchTicketsUpdate(updates);

      dispatch(resetSelectedTickets());
    } catch (error) {
      console.error('Failed to batch update tickets', updates);
    }
  }
);
