import md5 from 'js-md5';

export const getTicketHash = (id: number, created: number, useNewHash = true) => {
  const hash = md5(`TSK${id}${created.toString()}`);
  return useNewHash ? `_${hash.substring(0, 6)}` : hash.substring(0, 2);
};

export const getTicketWithHash = (id: number, created: number) => {
  const hash = getTicketHash(id, created);
  return `TSK${id}${hash}`;
};

export const isValidHash = ({
  contentId,
  created,
  originalHash,
  isDisabledOldFormat = false
}: {
  contentId: number;
  created: number;
  originalHash?: string;
  isDisabledOldFormat?: boolean;
}) => {
  if (!originalHash) {
    return false;
  }
  const newHash = getTicketHash(contentId, created, true);

  if (isDisabledOldFormat) {
    return newHash === `_${originalHash}`;
  }

  const oldHash = getTicketHash(contentId, created, false);
  return `_${originalHash}` === newHash || originalHash === oldHash;
};

export const checkSubjectForTSKDuplicates = (title: string, id: number, created: number) => {
  const newHash = getTicketHash(id, created, true);
  const oldHash = getTicketHash(id, created, false);

  const newFormat = `[TSK${id}${newHash}]`;
  const oldFormat = `[TSK${id}${oldHash}]`;

  const isTSKHashAlreadyUsed = title.includes(newFormat) || title.includes(oldFormat);

  if (!isTSKHashAlreadyUsed) {
    title = `${title} ${newFormat}`;
  }

  return title;
};
