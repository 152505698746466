import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

import { addTicketlistTab } from 'src/actions/CaseListActions';
import { StaticTabs } from 'src/types/TicketList';

export interface TicketListActiveTabs {
  main: string;
  tools?: 'filter' | 'search';
}

const initialState: TicketListActiveTabs = { main: StaticTabs.MAIN_VIEW };

const ticketListActiveTabsSlice = createSlice({
  name: 'ticketListActiveTabs',
  initialState,
  reducers: {
    setTicketListActiveTab(state, { payload }: PayloadAction<string>) {
      state.main = payload;
    },
    setToolsActiveTab(state, { payload }: PayloadAction<TicketListActiveTabs['tools']>) {
      state.tools = payload;
    },
    toggleToolsActiveTab(state, { payload }: PayloadAction<TicketListActiveTabs['tools']>) {
      state.tools = state.tools !== payload ? payload : undefined;
    },
    resetTicketListActiveTab(state) {
      state.main = StaticTabs.MAIN_VIEW;
    }
  },
  extraReducers(builder) {
    builder.addCase(addTicketlistTab, (state, { payload }) => {
      state.main = payload.id;
    });
  }
});

export const { setTicketListActiveTab, setToolsActiveTab, toggleToolsActiveTab, resetTicketListActiveTab } =
  ticketListActiveTabsSlice.actions;

export default ticketListActiveTabsSlice.reducer;
