import { Formik } from 'formik';
import { Form, Select } from 'formik-semantic-ui-react';
import iziToast from 'izitoast';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormField, FormGroup } from 'semantic-ui-react';
import * as yup from 'yup';

import type { FC } from 'react';

import EnvSettings from './api/EnvSettings';
import Button from './Components/generic/Button/Button';
import Input from './Components/generic/Input/Input';
import Modal from './Components/generic/Modal/Modal';
import Textarea from './Components/generic/Textarea/Textarea';
import { publicHttpClient } from './Utilities/httpClients';

interface FeedbackProps {}

const Feedback: FC<FeedbackProps> = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <Modal
      open={open}
      centered={false}
      closeOnEscape
      closeOnDimmerClick
      closeOnDocumentClick
      closeIcon
      header={<h3>{t('FEEDBACK_HEADER')}</h3>}
      trigger={
        <Button
          type="transparent"
          onClick={() => {
            setOpen(true);
          }}
        >
          {t('FEEDBACK_TRIGGER')}
        </Button>
      }
      onClose={() => {
        setOpen(false);
      }}
    >
      <Formik
        initialValues={{ email: '', idea: '', title: '', content: '', files: [] as File[] }}
        onSubmit={(data) => {
          const formData = new FormData();
          for (const key in data) {
            formData.append(key, data[key]);
          }

          return publicHttpClient
            .post(`${EnvSettings.getSettings().FEEDBACK_URL}`, data, {
              headers: { 'Content-Type': 'multipart/form-data' },
              maxRedirects: 0
            })
            .then(() => {
              setOpen(false);
              iziToast.success({
                title: t('OK'),
                icon: 'icon check',
                message: t('SUCCESS'),
                timeout: 5000
              });
            })
            .catch((error) => {
              console.error('Sending feedback failed:', error);
              iziToast.error({
                title: `${t('ERROR')}!`,
                icon: 'icon delete',
                message: `${t('FAILED')}!`,
                timeout: 5000
              });
            });
        }}
        validationSchema={yup.object().shape({
          email: yup.string().email().required(t('FEEDBACK_FILED_REQUIRED')),
          idea: yup.string().required(),
          title: yup.string().required(t('FEEDBACK_FILED_REQUIRED')),
          content: yup.string().required(t('FEEDBACK_FILED_REQUIRED')),
          files: yup.mixed().optional()
        })}
      >
        {({ isSubmitting, isValid, values, errors, handleChange, setFieldValue }) => (
          <Form style={{ padding: '24px' }}>
            <FormGroup widths={'equal'}>
              <FormField>
                <label>{t('FEEDBACK_EMAIL')}</label>
                <Input value={values.email} name="email" errorMessage={errors.email} onChange={handleChange} />
              </FormField>

              <FormField>
                <label>{t('FEEDBACK_CATEGORY')}</label>
                <Select
                  search
                  selection
                  name="idea"
                  value={values.idea}
                  error={!!errors.idea}
                  options={[
                    { text: t('FEEDBACK_OPTION_BUG'), value: 'bug' },
                    { text: t('FEEDBACK_OPTION_IDEA'), value: 'idea' },
                    { text: t('FEEDBACK_OPTION_OTHER'), value: 'other' }
                  ]}
                  onChange={handleChange}
                />
              </FormField>
            </FormGroup>

            <FormField>
              <label>{t('FEEDBACK_TITLE')}</label>
              <Input name="title" errorMessage={errors.title} onChange={handleChange} />
            </FormField>

            <FormField>
              <label>{t('FEEDBACK_CONTENT')}</label>
              <Textarea name="content" value={values.content} error={errors.content} onChange={handleChange} />
            </FormField>

            <FormField>
              <label>{t('FEEDBACK_FILES')}</label>
              <Input
                name="files"
                type="file"
                multiple
                errorMessage={((errors.files ?? []) as string[]).join(', ')}
                onChange={(event) => {
                  const files = event.target.files ? Array.from(event.target.files) : [];

                  setFieldValue('files', files);
                }}
              />
              {values.files?.length > 0 && (
                <>
                  <h4>{t('FEEDBACK_SELECTED_FILES')}:</h4>
                  {values.files.map((file) => (
                    <div>{file.name}</div>
                  ))}
                </>
              )}
            </FormField>

            <FormField style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button type="primary" htmlType="submit" isLoading={isSubmitting} disabled={!isValid || isSubmitting}>
                {t('GENERAL_SUBMIT')}
              </Button>
            </FormField>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default React.memo(Feedback);
