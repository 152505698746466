import { motion } from 'framer-motion';
import { filter, orderBy, partition } from 'lodash-es';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import type { WidgetName, WidgetOptions } from '@eeedo/types';
import type { FC } from 'react';

import Label from 'src/Components/generic/Label/Label';
import Section from 'src/Components/generic/Section/Section';
import { useAppSelector } from 'src/store';
import { filterNonDefaultTags, filterTagsByCategoryIds } from 'src/types/Tag';

import type { TicketWithActiveProperty } from 'src/reducers/ticketReducer';
import type { State } from 'src/types/initialState';

interface WidgetPopupContentProps {
  displayName?: string;
  name: WidgetName;
  options?: WidgetOptions;
}

interface TagProps {
  ticketTags: TicketWithActiveProperty['tags'];
  options?: WidgetOptions;
}

const TagWidgetLabel: FC<TagProps> = React.memo(
  ({ ticketTags, options }) => {
    const tags = useSelector(({ tags }: State) => tags);
    const categoryIds = options?.categoryIds || [];
    const tagsFiltered = categoryIds.length ? filterTagsByCategoryIds(tags, categoryIds) : filterNonDefaultTags(tags);
    const tagsSorted = orderBy(tagsFiltered, ['weight', 'name'], ['desc', 'asc']);
    const [selectedTags] = partition(tagsSorted, (tag) => ticketTags.includes(tag.id));
    return <Label>{selectedTags.length}</Label>;
  },
  (prev, next) => prev.ticketTags === next.ticketTags && prev.options?.categoryIds === next.options?.categoryIds
);

const WidgetPopupContent: FC<WidgetPopupContentProps> = ({ displayName, name, options }) => {
  const { t } = useTranslation();
  const task = useAppSelector((state) => state.detailedTickets.find((ticket) => ticket.id === state.activeTicketTab));

  const getWidgetLabel = useMemo(
    () =>
      (widgetName: WidgetName): React.ReactNode => {
        const widgetLabels: Partial<Record<WidgetName, React.ReactNode>> = {
          Attachments: (
            <Label>
              {
                filter(
                  task?.attachments.filter((attachment) => !attachment.isDuplicated),
                  (att) => !att['deprecated']
                ).length
              }
            </Label>
          ),
          Tags: <TagWidgetLabel ticketTags={task?.tags || []} options={options} />,
          Delegations: <Label>{filter(task?.delegatedTo, (delegate) => delegate !== 'USR1').length}</Label>
        };
        return widgetLabels[widgetName];
      },
    [task]
  );

  return (
    <motion.div
      initial={{ opacity: 0, transform: 'translateX(-10px)' }}
      animate={{ opacity: 1, transform: 'translateX(0px)' }}
      exit={{ opacity: 0, transform: 'translateX(-10px)' }}
    >
      <Section gap={4} display="flex" align="center">
        {displayName ?? t(`widgets_menu.${name}`, { defaultValue: name })}
        {getWidgetLabel(name)}
      </Section>
    </motion.div>
  );
};

export default React.memo(WidgetPopupContent);
