import { ChannelType } from '@eeedo/types';
import React, { memo, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';
import { Accordion } from 'semantic-ui-react';

import EnvSettings from 'src/api/EnvSettings';
import FeatureFlags from 'src/api/FeatureFlags';
import { EnreachSSO } from 'src/Components/PhoneServices/EnreachSSO';
import ElisaOCContainer from 'src/containers/ElisaOCContainer';
import ElisaRingContainer from 'src/containers/ElisaRingContainer';
import EnreachVoiceContainer from 'src/containers/EnreachVoiceContainer';
import MitelIntegrationContainer from 'src/containers/MitelIntegrationContainer';
import { hasPhoneIntegrations } from 'src/Utilities/phoneIntegrations';

import type { State } from 'src/types/initialState';

const userHasPermissionsCheck = (userData: State['userData']) => {
  return userData.permissions.includes('allowPhoneIntegrations') && userData.channels.includes(ChannelType.Phone);
};

const PhoneIntegrations = () => {
  const userHasPermissions = useSelector((state: State) => userHasPermissionsCheck(state.userData));

  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { hasElisaOCIntegration, hasElisaRingIntegration, hasMitelIntegration, hasEnreachVoiceIntegration } =
    hasPhoneIntegrations();

  const getHotKeyHandlerOptions = (key: number): Parameters<typeof useHotkeys> => [
    `alt+${key}`,
    (event: KeyboardEvent) => {
      event.preventDefault();
      setActiveIndex(key === activeIndex ? null : key);
    },
    undefined,
    [activeIndex, setActiveIndex]
  ];

  useHotkeys(...getHotKeyHandlerOptions(4));
  useHotkeys(...getHotKeyHandlerOptions(5));
  useHotkeys(...getHotKeyHandlerOptions(6));
  useHotkeys(...getHotKeyHandlerOptions(7));

  const setIsOpen = (index: number) => (active: boolean) => {
    setActiveIndex(active ? index : null);
  };

  let integrationKey = 4;

  if (!userHasPermissions) {
    return null;
  }

  return (
    <Accordion exclusive>
      {FeatureFlags.isFlagOn('ENABLE_ENREACH_VOICE_CLICK_TO_CALL') && hasEnreachVoiceIntegration && <EnreachSSO />}

      {hasElisaOCIntegration && (
        <ElisaOCContainer
          url={EnvSettings.getSettings().OC_URL}
          isOpen={activeIndex === integrationKey}
          setIsOpen={setIsOpen(integrationKey++)}
        />
      )}
      {hasElisaRingIntegration && (
        <ElisaRingContainer
          url={EnvSettings.getSettings().ELISARING_URL}
          isOpen={activeIndex === integrationKey}
          setIsOpen={setIsOpen(integrationKey++)}
        />
      )}
      {hasMitelIntegration && (
        <MitelIntegrationContainer
          url={EnvSettings.getSettings().MITEL_URL}
          isOpen={activeIndex === integrationKey}
          setIsOpen={setIsOpen(integrationKey++)}
        />
      )}
      {hasEnreachVoiceIntegration && (
        <EnreachVoiceContainer
          url={EnvSettings.getSettings().ENREACH_VOICE_URL}
          isOpen={activeIndex === integrationKey}
          setIsOpen={setIsOpen(integrationKey++)}
        />
      )}
    </Accordion>
  );
};

export default memo(PhoneIntegrations);
