import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import type { ConnectedProps } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';

import { addTab } from '../actions/tabActionsRTK';
import { falsifyInstantScroll, falsifyScroll, fetchTicket } from '../actions/ticketsActions';
import { Task } from '../api/Task';
import Case from '../Components/Case/Case';
import SocketInstance from '../realTimeNotifications';
import { taskIdToNumericalId } from '../Utilities/ticketList';
import { convertCaseNaming } from 'src/Utilities/helper';

import type { State } from 'src/types/initialState';
import type { ThunkAppDispatch } from 'src/types/store';

const mapStateToProps = (state: State, ownProps: any) => {
  const id = ownProps.match.params.id;
  // TODO: rase with fire after merge with migration to vite.js (ask Oleh)
  let task: any = new Task();

  if (state.userData && state.userData.userPreferences && state.userData.userPreferences.defaultTicketType) {
    task.taskType = state.userData.userPreferences.defaultTicketType;
  }
  if (id !== 'new' && id !== 'new_oc') {
    task = state.detailedTickets.find((ticket) => ticket.id === state.activeTicketTab);
  }

  return {
    task,
    usersList: state.usersList.usersList,
    ticketTypes: state.ticketTypes,
    userData: state.userData,
    mobileMode: state.mobileReducer.mobileMode,
    needToScroll: state.drafts.scroll,
    scrollInstantly: state.drafts.scrollInstantly,
    iFrame: state.caseIFrameUrls.frames.get(taskIdToNumericalId(task?.id) ?? -1),
    activeSingleLineComment: state.commentsMenu.activeSingleLineComment
  };
};

const mapDispatchToProps = (dispatch: ThunkAppDispatch) => {
  return {
    fetchTicket: (id: string) => {
      dispatch(fetchTicket({ id }));
    },
    falsifyScroll: () => {
      dispatch(falsifyScroll());
    },
    falsifyInstantScroll: () => {
      dispatch(falsifyInstantScroll());
    },
    openTab: (id: string) => {
      SocketInstance.joinRoom(convertCaseNaming(id, 'string', 'task') as string);
      dispatch(addTab({ id, type: 'tickets' }));
    }
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface CaseHOCProps extends RouteComponentProps<{ id: string }>, ConnectedProps<typeof connector> {}

export default withRouter(connector(Case));
